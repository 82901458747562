import {useMemo, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createMusicianApiCall} from "src/utils/apiCalls";


const CreateMusician = () => {
    const inputRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            }
        };
    }, [access_token]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            email: e.target.email.value,
            stage_name: e.target.stageName.value
        }

        createMusicianApiCall(payload, config, dispatch, (res) => {
            inputRef.current.reset();
        });

    };

    return (
        <div>
            <h1>Create Musician</h1>

            <form onSubmit={handleSubmit} ref={inputRef}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%'
                }}>
                    <StyledInput
                        name="email"
                        type="email"
                        placeholder="Email"
                        required
                    />

                    <StyledInput
                        name="stageName"
                        type="text"
                        placeholder="Stage Name"
                        required
                    />
                    <button type="submit"
                            className="SubmitButton"
                            style={{
                                cursor: 'pointer',
                                marginTop: '1rem',
                                height: '2rem',

                            }}>
                        Submit
                    </button>
                </div>

            </form>
        </div>

    );
}

const StyledInput = styled.input`
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-top: 0.5rem;
    border: 2px solid #000000;
    border-radius: 4px;
    font-family: "IBM Plex Sans Regular";
    font-size: 1rem;
`;

export default CreateMusician;