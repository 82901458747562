import axios from "axios";
import React, {useState, useEffect, useMemo, useCallback} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import AdminCampaign from "../../components/admin-components/AdminCampaign";
import SearchBar from "../../components/admin-components/SearchBar";
import {Pagination} from "react-pagination-bar";
import {useHistory, useLocation} from "react-router-dom";
import {getCampaignsApiCall} from "src/utils/apiCalls";
import {setSongGenresAction} from "../../actions/setSongGenresAction";

const PER_PAGE = 5;

const AdminCampaigns = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [campaigns, setCampaigns] = useState([]);
    const [totalSongCount, setTotalSongCount] = useState(0);
    const [urlEnding, setUrlEnding] = useState("");
    const [loading, setLoading] = useState(true);

    const headerText = urlEnding === 'active' ? "Active Campaigns" : (urlEnding === 'archived' ? 'Archived Campaigns' : 'All Campaigns');

    const accessToken = useSelector((state) => state.user.access_token, shallowEqual);
    const stateGenres = useSelector((state) => state.genres.genres, shallowEqual);

    const searchParams = new URLSearchParams(location.search);
    const {user, page, musician} = Object.fromEntries(searchParams.entries());
    const currentPage = parseInt(page, 10) || 1;

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
    }, [accessToken]);

    const extractCampaignStatus = (url) => {
        const campaignIndex = url.indexOf('campaigns/');
        if (campaignIndex === -1) return null;

        return url.substring(campaignIndex + '/campaigns'.length);
    };

    const handlePageChange = useCallback((pageNumber) => {
        const params = new URLSearchParams({ page: pageNumber });

        if (user) {
            params.append('user', user);
        }

        if (musician) {
            params.append('musician', musician);
        }

        history.push(`?${params.toString()}`);
    }, [history, musician, user]);

    useEffect(() => {
        const localUrlEnding = extractCampaignStatus(location.pathname);
        setUrlEnding(localUrlEnding)

        const newConfig = {
            ...config,
            params: {
                per_page: PER_PAGE,
                page: currentPage,
                campaign_status: localUrlEnding
            },
        };

        if (user) {
            newConfig.params.search_string = user
        }

        if (musician) {
            newConfig.params.musician_id = musician
        }

        getCampaignsApiCall(newConfig, dispatch, (res) => {
            setCampaigns(res.data.data.items)
            setTotalSongCount(res.data.data._meta.total_items)
        },
            () => setLoading(false));
    }, [location, user, currentPage, config, dispatch, musician]);


    useEffect(() => {
        if (!stateGenres) {
            axios.get("/api/admin/songs/genres", config)
                .then((res) => {
                    const genres = res.data.data.items;
                    dispatch(setSongGenresAction(genres));
                });
        }
    }, [config, dispatch, stateGenres]);

    return (
        <>
            <AllPromotionsDiv>
                <>
                    <SearchBar headerText={headerText}/>
                    {campaigns.map((campaign) =>
                        <AdminCampaign campaign={campaign} key={campaign.id}/>
                    )}

                    {!loading && totalSongCount === 0 && (
                        <h2 style={{marginTop: "4rem"}}>No campaigns found</h2>
                    )}
                </>
            </AllPromotionsDiv>


            {
                totalSongCount !== 0 &&
                <PaginationContainer>
                    <StyledMyPagination
                        initialPage={currentPage}
                        itemsPerPage={PER_PAGE}
                        onPageСhange={(handlePageChange)}
                        pageNeighbours={3}
                        totalItems={totalSongCount}
                        customClassNames={{
                            rpbItemClassName: "custom-item",
                            rpbItemClassNameActive: "custom-item--active",
                            rpbGoItemClassName: "custom-go-item",
                            rpbItemClassNameDisable: "custom-item--disable",
                            rpbRootClassName: "custom-root",
                        }}
                    />
                </PaginationContainer>
            }
        </>
    );
};

export default AdminCampaigns;

const AllPromotionsDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;
`;

const StyledMyPagination = styled(Pagination)`
    .custom-item {
        border-radius: 50px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        min-width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        cursor: pointer;
        background-color: #180139;
        color: #fff;
    }

    .custom-item--active {
        background-color: rgb(104, 0, 255);
        border-radius: 50px;
        color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        min-width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }

    .custom-item--disable {
        opacity: 0.5;
    }

    .custom-root ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .custom-go-item {
        align-items: center;
        display: flex;
        font-size: 1rem;
        padding-inline-end: 0.75rem;
        padding-inline-start: 0.75rem;
        width: 150px;
    }

    .custom-go-item input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 40px;
        width: 100%;
    }

    .custom-progress-bar {
        background-color: #9e75ff;
        border-radius: 5px;
        box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
        height: 3px;
        margin: 1rem 0;
        max-width: 100%;
        transition: width 0.2s ease;
    }
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
`;