import React from "react";
import './errorStyle.css';
import {shallowEqual, useSelector} from "react-redux";

const SomethingWentWrong = () => {
    const user = useSelector((state) => state.user.info, shallowEqual);

    const homePage = user.user_type === 'musician' ? process.env.REACT_APP_MUSICIANS_HOMEPAGE :
        process.env.REACT_APP_CREATORS_HOMEPAGE;

    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>5<span></span>0</h1>
                </div>
                <h2>Oops! Something went wrong</h2>
                <p>It seems we're having some technical troubles. Please try refreshing the page or check back shortly. We're working to get things back to normal as soon as possible. Thank you for your patience!</p>
                <button onClick={() => window.location = homePage}>Back to homepage</button>
            </div>
        </div>
    );
};

export default SomethingWentWrong;
