import {AddToast} from "src/actions/SetToastsAction";
import {useDispatch} from "react-redux";


const useNotifications = () => {
    const dispatch = useDispatch();

    const showSuccess = (message) => {
        dispatch(
            AddToast({
                message: message,
                type: "success",
                color: "#00CDA8"
            })
        )
    };

    const showError = (message) => {
        dispatch(
            AddToast({
                message: message,
                type: "error",
                color: "#F06666"
            })
        )
    }

    return {
        showSuccess, showError
    };
};

export default useNotifications;