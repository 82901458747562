import React, {useMemo, useState} from "react";
import {updateAdminCreatorApiCall, getCountriesApiCall} from "../../utils/apiCalls";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const EditCreatorForm = ({user, countries}) => {
    const creator = user.creator;
    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);

    const [selectedLevelType, setSelectedLevelType] = useState(creator?.level?.type);
    const [selectedCountryId, setSelectedCountryId] = useState(creator?.country?.id || "");

    const handleCountryChange = (e) => {
        setSelectedCountryId(e.target.value);
    }

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            }
        };
    }, [access_token]);

    const updateCreator = (e) => {
        e.preventDefault();

        const creatorData = e.target;

        const payload = {
            user_id: user.id,
            tiktok_username: creatorData.tiktokUsername.value || null,
            tiktok_followers: creatorData.tiktokFollowers.value || null,
            level_type: selectedLevelType,
        }

        if (selectedLevelType === 'normal') {
            payload.level_id = parseInt(creatorData['normal-level'].value) + 1
        }
        else if (selectedLevelType === 'linear') {
            payload.per_1k_views_amount_eur = creatorData['linear'].value || null
            payload.max_amount_eur = creatorData['max_amount_eur'].value || null
        }
        else if (selectedLevelType === 'fixed') {
            payload.max_amount_eur = creatorData['fixed'].value || null
        }

        if (creatorData.country.value !== "Select a country") {
            payload.country_id = creatorData.country.value;
        }

        updateAdminCreatorApiCall(creator.id, payload, config, dispatch, (res) => {
            setTimeout(() => window.location.reload(), 2000)
        })
    }

    return <div className="header" style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: "2rem",
        marginLeft: "1rem"
    }}>
        <form
            className="UpdateForm"
            onSubmit={(e) => updateCreator(e)}
        >
            <div
                className={"tiktok-div"}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <p style={{margin: "0.5rem", padding: 0, fontSize: "13px"}}>
                    {"TikTok Username:"}
                </p>
                <input
                    name={"tiktokUsername"}
                    type="text"
                    placeholder={"TikTok Username"}
                    defaultValue={creator.tiktok_username}
                />
            </div>

            <div
                className={"tiktok-div"}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <p style={{margin: "0.5rem", padding: 0, fontSize: "13px"}}>
                    {"TikTok Followers:"}
                </p>
                <input
                    name={"tiktokFollowers"}
                    type="text"
                    placeholder={"TikTok Followers"}
                    defaultValue={creator.tiktok_followers}
                />
            </div>

            <form onChange={(e) => setSelectedLevelType(e.target.value)}>
                <input type="radio" id="normal" name="level" value="normal"
                       defaultChecked={selectedLevelType === 'normal'}/>
                <label htmlFor="normal">Normal Level</label>

                <input type="radio" id="linear" name="level" value="linear"
                       defaultChecked={selectedLevelType === 'linear'}/>
                <label htmlFor="linear">Linear Deal</label>

                <input type="radio" id="fixed" name="level" value="fixed"
                       defaultChecked={selectedLevelType === 'fixed'}/>
                <label htmlFor="fixed">Fixed Deal</label>
            </form>

            {
                selectedLevelType === 'normal' &&
                <div
                    className={"normal-level-div"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <p style={{margin: "0.5rem", padding: 0, fontSize: "13px"}}>
                        {"Level:"}
                    </p>
                    <input
                        name={"normal-level"}
                        type="text"
                        placeholder={"Level"}
                        defaultValue={creator.level?.type === 'normal' ? creator.level.name : null}
                    />
                </div>
            }

            {
                selectedLevelType === 'linear' &&
                <div
                    className={"linear-div"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <input
                        title={"Payment per 1000 views"}
                        name={"linear"}
                        type="text"
                        placeholder={"Payment per 1000 views"}
                        defaultValue={creator.level?.type === 'linear' ? creator.level?.per_1k_views_amount_eur : null}
                    />
                    <input
                        title={"Max amount euro"}
                        name={"max_amount_eur"}
                        type="text"
                        placeholder={"Max amount euro"}
                        defaultValue={creator.level?.type === 'linear' ? creator.level?.max_amount_eur : null}
                    />
                </div>
            }

            {selectedLevelType === 'fixed' &&
                <div
                    className={"fixed-payment-div"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <p style={{margin: "0.5rem", padding: 0, fontSize: "13px"}}>
                        {"Fixed payment:"}
                    </p>
                    <input
                        name={"fixed"}
                        type="text"
                        placeholder={"Payment per video"}
                        defaultValue={creator.level?.type === 'fixed' ? creator.level?.max_amount_eur : null}
                    />
                </div>
            }

            <div
                className="country-div"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <p style={{margin: "0.5rem", padding: 0, fontSize: "13px"}}>
                    Country:
                </p>
                <select
                    name="country"
                    required
                    value={selectedCountryId}
                    onChange={handleCountryChange}
                    style={{cursor: 'pointer'}} // Optional styling
                >
                    <option value="" disabled>Select a country</option>
                    {countries.map((country, index) => (
                        <option key={index} value={country.id}>{country.name}</option>
                    ))}
                </select>
            </div>
            <input type="submit" value="Save"/>
        </form>
    </div>
}

export default EditCreatorForm;