import React, {useEffect, useMemo, useRef, useState} from "react";
import Multiselect from "multiselect-react-dropdown";
import {Editor} from "@tinymce/tinymce-react";
import {updateCampaignApiCall} from "src/utils/apiCalls";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {AddToast} from "src/actions/SetToastsAction";
import FileInputComponent from "./FileInputComponent";
import {uploadFilesAndUpdatePayload} from "src/services/aws/s3";
import {formatToDateTimeUTC} from "src/utils/utils";

const EditCampaignForm = ({campaign}) => {
    const song = campaign.song;
    const dispatch = useDispatch();

    const editorRef = useRef(null);
    const territoriesRef = useRef(0);

    const [genre, setGenre] = useState(song.genre.id);
    const [musician, setMusician] = useState(campaign.musician?.id);
    const [maxLevel, setMaxLevel] = useState(campaign.max_level_id);
    const [description, setDescription] = useState(campaign.description ?? '');
    const [musicians, setMusicians] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [songFile, setSongFile] = useState(null);

    const stateGenres = useSelector((state) => state.genres.genres, shallowEqual);
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        };
    }, [access_token]);

    const updateCampaign = async (e) => {
        e.preventDefault();

        const formData = e.target;

        const territories = territoriesRef.current.getSelectedItems().map((a) => a.name);

        const startDate = new Date(formData.startDate.value);
        const endDate = new Date(formData.endDate.value);

        let formattedStartDateInUTC, formattedEndDateInUTC = null;

        if (endDate.getTime() > startDate.getTime()) {
            formattedStartDateInUTC = formatToDateTimeUTC(startDate.toISOString());
            formattedEndDateInUTC = formatToDateTimeUTC(endDate.toISOString());
        } else {
            return dispatch(
                AddToast({
                        message: "End date should be greater than start date.",
                        type: "error",
                        color: "#F06666",
                    }
                ))
        }

        let payload = {
            description,
            budget_eur: formData.budgetEur.value,
            max_level_id: maxLevel,
            hashtags: [formData.hashtag1.value, formData.hashtag2.value, formData.hashtag3.value],
            musician_id: musician,
            start_date: formattedStartDateInUTC ?? campaign.start_date,
            end_date: formattedEndDateInUTC ?? campaign.end_date,
            song: {
                id: song.id,
                genre_id: genre,
                tiktok_url: formData.tiktokUrl.value ?? song.tiktok_url,
                capcut_url: formData.capcutUrl?.value ?? song?.capcut_url,
                title: formData.songTitle.value ?? song.title,
                artist: formData.songArtist.value ?? song.artist,
                album_icon_url: song.album_icon_url,
                google_drive_url: song.google_drive_url
            }
        };

        if (formData.spotifyUrl.value) {
            payload.song.spotify_url = formData.spotifyUrl.value;
        }

        if (territories.length !== 0) {
            payload.territories = territories;
        }

        if (imageFile || songFile) {
            const uploadResult = await uploadFilesAndUpdatePayload(imageFile, songFile, payload, dispatch);

            if (uploadResult) {
                payload = uploadResult;
            } else {
                return;
            }
        }

        updateCampaignApiCall(campaign.id, payload, config, dispatch, () => {
            // setTimeout(() => window.location.reload(), 1000);
        });
    };

    useEffect(() => {
        axios.get('/api/admin/musicians', {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            params: {
                per_page: 70,
            },
        })
            .then(res => setMusicians(res.data.data.items))
            .catch(err => {
                dispatch(
                    AddToast({
                        message: err.response?.data?.message ?? "Error, contact support!",
                        type: "error",
                        color: "#F06666",
                    })
                );
            });
    }, [access_token, dispatch]);

    return <div className="header">
        <form className="UpdateForm" onSubmit={updateCampaign}>
            <input name="songTitle" type="text" placeholder="Song Title"
                   defaultValue={song.title}/>
            <input name="songArtist" type="text" placeholder="Artist" defaultValue={song.artist}/>
            <Editor
                id="Challenge"
                apiKey='4m46fx15tvuq3ejzyuj2fh6a2y9ffu0gb96c84owdoud52j2'
                onInit={(evt, editor) => editorRef.current = editor}
                value={description}
                onEditorChange={(inputCampaignDescription, editor) => setDescription(inputCampaignDescription)}
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />

            <label htmlFor="startDate" className="startDate-label" style={{marginTop: "1rem", marginLeft: "0.5rem"}}>Start
                Date</label>
            <input name="startDate" type="datetime-local"
                   title={"Scheduled start date of the campaign"}
                   placeholder="Scheduled start date of the campaign" style={{marginTop: "0rem"}}
                   defaultValue={campaign.start_date} required/>
            <label htmlFor="endDate" className="endDate-label" style={{marginTop: "0.5rem", marginLeft: "0.5rem"}}>End
                Date</label>
            <input name="endDate" type="datetime-local"
                   title={"Scheduled end date of the campaign"}
                   placeholder="Scheduled start date of the campaign" style={{marginTop: "0"}}
                   defaultValue={campaign.end_date} required/>

            <input name="budgetEur" type="number" min="1" step="any" placeholder="CampaignRow budget"
                   defaultValue={campaign.budget_eur}/>
            <input name="spotifyUrl" type="url" pattern="https://.*" placeholder="Spotify Link of the song"
                   defaultValue={song.spotify_url}/>
            <input name="tiktokUrl" type="url" pattern="https://.*" placeholder="Tiktok Link of the song"
                   defaultValue={song.tiktok_url}/>
            <input name="capcutUrl" type="url" pattern="https://.*" placeholder="Capcut Link for the song"
                   defaultValue={song.capcut_url}/>

            {
                song.google_drive_url && (
                    <>
                        <label htmlFor="file-upload" className="file-upload-label" style={{margin: "0 0.5rem"}}>
                            Album Cover
                        </label>
                        <FileInputComponent
                            defaultFileName={`${song.album_icon_url}`}
                            fileType="image/*"
                            inputName={"albumIcon"}
                            onFileChange={(file) => setImageFile(file)}
                        />
                        <label htmlFor="file-upload" className="file-upload-label"
                               style={{margin: "0 0.5rem"}}>Song</label>
                        <FileInputComponent
                            defaultFileName={`${song.google_drive_url}`}
                            fileType=".mp3,audio/*"
                            inputName={"s3Song"}
                            onFileChange={(file) => setSongFile(file)}
                        />
                    </>
                )
            }

            <input name="hashtag1" type="text" placeholder="#Hashtag1" defaultValue={campaign?.hashtags[0] || ""}/>
            <input name="hashtag2" type="text" placeholder="#Hashtag2" defaultValue={campaign?.hashtags[1] || ""}/>
            <input name="hashtag3" type="text" placeholder="#Hashtag3" defaultValue={campaign?.hashtags[2] || ""}/>

            <Multiselect
                style={{
                    multiselectContainer: {
                        marginLeft: "0.5rem",
                        padding: "0.5rem 1rem 0.5rem 0rem",
                    },
                }}
                ref={territoriesRef}
                options={[
                    {name: "all", id: 8},
                    {name: "GSA", id: 4},
                    {name: "Europe", id: 5},
                    {name: "North America", id: 2},
                    {name: "Asia", id: 6},
                    {name: "LATAM", id: 1},
                    {name: "Africa", id: 3},
                    {name: "Oceania", id: 7},
                ]}
                placeholder="Available territories"
                displayValue="name"
                selectedValues={campaign.territories}
            />

            <Select name="maxLevel"
                    style={{
                        height: "2rem",
                        margin: "0rem 0.5rem 0.5rem 0.5rem",
                    }}
                    value={maxLevel}
                    title="Max Level"
                    onChange={(e) => setMaxLevel(e.target.value)}
            >
                {
                    Array.from({length: 7}, (_, index) => (
                        <MenuItem key={index} value={index + 1}>
                            {`Level ${index}`}
                        </MenuItem>
                    ))
                }
            </Select>

            <Select name="genre"
                    style={{
                        height: "2rem",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                        marginBottom: "0.5rem"
                    }}
                    value={genre}
                    title="Genre"
                    onChange={(e) => setGenre(e.target.value)}
            >
                {stateGenres.map((genre) => (
                    <MenuItem value={genre.id} key={genre.id}>{genre.name}</MenuItem>
                ))}
            </Select>

            <Select name="musician"
                    style={{
                        height: "2rem",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                        marginBottom: "0.5rem"
                    }}
                    value={musician}
                    title="Musician"
                    onChange={(e) => setMusician(e.target.value)}
            >
                {musicians.map((musician) => (
                    <MenuItem value={musician.id} key={musician.id}>{musician.stage_name}</MenuItem>
                ))}
            </Select>

            <input type="submit" value="Save"/>
        </form>
    </div>
}

export default EditCampaignForm;