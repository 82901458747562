const initState = {
    ToastMessages: [],
    ShowVerifyTikTok: 0,
};

export const ToastReducer = (state=initState, action) =>{
    switch (action.type) {
        case "ADD_MESSAGE":
            let message = action.payload.message;
            message.id = state.ToastMessages.length + 1;
            return{
                ...state,
                ToastMessages: state.ToastMessages.concat(message)
            };
        case "DELETE_MESSAGE":
            return{
                ...state,
                ToastMessages: state.ToastMessages.filter((item, index) =>{
                    return item.id !== action.payload.id;
                })
            };
        case "SHOW_VERIFY":
            return{
                ...state,
                ShowVerifyTikTok: 1
            };
        case "HIDE_VERIFY":
            return{
                ...state,
                ShowVerifyTikTok: 0
            };
        default:
            return {...state}
    }
};