import {shallowEqual, useDispatch, useSelector} from "react-redux";
import React, {useMemo, useRef, useState} from "react";
import PersonIcon from "@material-ui/icons/Person";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Chip from "@material-ui/core/Chip";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AccountBoxIcon from "@material-ui/icons/AccountCircleOutlined";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import VerifiedIcon from '@mui/icons-material/Verified';
import {resetUserInformation} from "../../actions/userAction";
import {
    deletePromotionApiCall,
    deleteUserApiCall,
    getAdminCreatorPromotionsApiCall,
    updateUserApiCall
} from "src/utils/apiCalls";
import EditCreatorForm from "./EditCreatorForm";
import axios from "axios";
import {AddToast} from "../../actions/SetToastsAction";

const User = ({user, countries}) => {
    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);
    const IphoneTog = useRef(0);

    const [promotions, setPromotions] = useState([]);
    const [toggledDiv, setToggledDiv] = useState(null);
    const [deletePromotionText, setDeletePromotionText] = useState("Delete");
    const [deleteUserText, setDeleteUserText] = useState("Delete");
    const [editing, setEditing] = useState(false);
    const [creatorStatus, setCreatorStatus] = useState(user.creator?.status);

    const negatedCreatorStatus = creatorStatus === 'blocked' ? 'active' : 'blocked';

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            }
        };
    }, [access_token]);

    const getFullName = () => {
        if (user.first_name === null || user.last_name === null) {
            return "-";
        } else {
            return user.first_name + " " + user.last_name;
        }
    }

    const handleVideoLinkClick = (videoLink) => {
        if (!videoLink){
            dispatch(
                AddToast({
                    message: "No video link available",
                    type: "error",
                    color: "#F06666",
                })
            )
        }

        window.open(videoLink, "_blank");
    }

    const getCreatorPromotions = () => {
        setToggledDiv('promotions');

        const newConfig = {
            ...config,
            params: {
                per_page: 20,
            },
        };

        promotions?.length === 0 && getAdminCreatorPromotionsApiCall(user.creator.id, newConfig, dispatch, (res) => {
            setPromotions(res.data.data.items);
        })
    };

    const updateCreatorStatus = (status) => {
        const payload  = { status: status}
        axios
            .put(`/api/admin/creators/${user.creator.id}/status`, payload, config)
            .then(res => {
                setCreatorStatus(negatedCreatorStatus);

                dispatch(
                    AddToast({
                        message: res.data?.message ?? "Creator status updated successfully!",
                        type: "success",
                        color: "#00CDA8",
                    })
                )
            })
            .catch(err => {
                dispatch(
                    AddToast({
                        message: err?.response?.data?.message ?? "Error, contact support!",
                        type: "error",
                        color: "#F06666",
                    })
                );
            })
    }

    const updateUser = (payload) => {
        promotions?.length === 0 && updateUserApiCall(user.id, payload, config, dispatch, (res) => {
            dispatch(resetUserInformation(res.data.data.items))
        })
    }

    return (
        <PromotionDiv>
            <React.Fragment>
                <div className="UserData" style={{marginBottom: "2rem"}}>
                    <HeaderChip2
                        icon={user.verified ? <VerifiedIcon/> : <PersonIcon/>}
                        title="Fullname"
                        label={getFullName()}
                    />
                    <HeaderChip
                        icon={<AccountBoxIcon/>}
                        title="Tiktok username"
                        label={user.creator.tiktok_username ?? "-"}
                    />
                    <HeaderChip
                        icon={<StarBorderOutlinedIcon/>}
                        title="Level"
                        label={user.creator.level_id !== null ? user.creator.level_id - 1 : "-"}
                    />
                    <HeaderChip
                        icon={<PublicOutlinedIcon/>}
                        title="Country"
                        label={user.creator?.country?.name || "-"}

                    />
                    <HeaderChip
                        icon={<PeopleAltOutlinedIcon/>}
                        title="Tiktok Followers"
                        label={user.creator.tiktok_followers?.toLocaleString() ?? "-"}
                    />

                    <HeaderChip
                        icon={<MonetizationOnIcon/>}
                        title="Payable Status"
                        label={user.is_payable ? "Payable" : "Not Payable"}
                    />

                    <IphoneToggle>
                        <h3>Email</h3> &nbsp;
                        <div className="toggle">
                            <input type="checkbox"
                                   className="check"
                                   ref={IphoneTog}
                                   defaultChecked={user.is_notification_enabled}
                                   onClick={() => updateUser({is_notification_enabled: !user.is_notification_enabled})}/>
                            <b className="b switch"/>
                            <b className="b track"/>
                        </div>
                    </IphoneToggle>
                </div>

                {editing && <EditCreatorForm user={user} countries={countries}/>}

                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center"
                    }}
                >
                    <DeleteButton
                        onClick={() => setEditing(prev => !prev)}
                        variant="contained"
                        color="secondary"
                    >
                        <span>Edit</span>
                    </DeleteButton>

                    <DeleteButton
                        // onClick={(e) => updateUser(e, user.id, true)}
                        variant="contained"
                        color="secondary"
                    >
                        <span>Verify User</span>
                    </DeleteButton>

                    <div style={{marginLeft: "auto"}}>
                        <DeleteButton
                            variant="contained"
                            color="secondary"
                            onClick={() => updateCreatorStatus(negatedCreatorStatus)}
                        >
                                {creatorStatus === 'blocked' ? 'Unblock' : 'Block'}
                        </DeleteButton>
                        <DeleteButton
                            onClick={() => deleteUserText === "Delete" ? setDeleteUserText("Confirm") : deleteUserApiCall(user.id, config, dispatch, () => setTimeout(() => window.location.reload(), 2000))}
                            variant="contained"
                            color="secondary"
                        >
                            {deleteUserText}
                        </DeleteButton>
                    </div>
                </div>

                <div className="TogglableHeaders">
                    <StyledButtonGroup>
                        <StyledUIButton
                            onClick={() => toggledDiv === 'promotions' ? setToggledDiv(null) : getCreatorPromotions()}>
                            Videos
                        </StyledUIButton>
                        <StyledUIButton
                            onClick={() => toggledDiv === "UserDetails" ? setToggledDiv(null) : setToggledDiv("UserDetails")}>
                            Details
                        </StyledUIButton>
                    </StyledButtonGroup>
                </div>

                {toggledDiv === "promotions" && (
                    <UserPromosDiv className="ApprovedSongs">
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Video Link</strong></TableCell>
                                        <TableCell align="right"><strong>Promotion Time</strong></TableCell>
                                        <TableCell align="right"><strong>User</strong></TableCell>
                                        <TableCell align="right"><strong>Delete</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {promotions.map((promo) => (
                                        <TableRow key={promo.id}>
                                            <TableCell component="th" scope="row">
                                                <LinkButton
                                                    style={{
                                                        backgroundColor: "#000000",
                                                        marginLeft: "auto",
                                                        marginRight: "0.5rem",
                                                    }}
                                                    onClick={() => handleVideoLinkClick(promo.video_link)}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    <span>Videolink</span>
                                                </LinkButton>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Chip
                                                    style={{
                                                        backgroundColor: "#b1b1b1",
                                                        marginBlock: 0,
                                                    }}
                                                    className="chip"
                                                    icon={<AccessTimeOutlinedIcon/>}
                                                    label={promo.created_at}
                                                />

                                            </TableCell>
                                            <TableCell align="right">
                                                <Chip
                                                    style={{
                                                        backgroundColor: "#b1b1b1",
                                                        marginBlock: 0,
                                                    }}
                                                    className="chip"
                                                    icon={<AccountCircleOutlinedIcon/>}
                                                    label={promo.creator.tiktok_username}

                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <LinkButton
                                                    onClick={() => deletePromotionText !== 'Delete' ? setDeletePromotionText('Delete') : deletePromotionApiCall(promo.id, config, dispatch)}
                                                    color="secondary"
                                                    variant="contained"
                                                    disabled={!['pending', 'submitted'].includes(promo.status)}
                                                >
                                                    {deletePromotionText}
                                                </LinkButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </UserPromosDiv>
                )}

                {toggledDiv === "UserDetails" && (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>TikTok Profile</strong></TableCell>
                                    <TableCell><strong>Address</strong></TableCell>
                                    <TableCell align={"right"}><strong>Email</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Chip
                                            style={{
                                                backgroundColor: "#b1b1b1",
                                                marginBlock: 0,
                                            }}
                                            className="chip"
                                            icon={<AccountCircleOutlinedIcon/>}
                                            label={user.creator.tiktok_username ?? '-'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {`${user.street ?? ''} ${user.house_number ? user.house_number + ',' : ''} 
                                            ${user.city ? user.city + ',' : ''} ${user.creator.country?.name ?? ''}`}
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <Chip
                                            style={{
                                                backgroundColor: "#b1b1b1",
                                                marginBlock: 0,
                                            }}
                                            className="chip"
                                            icon={<AccountCircleOutlinedIcon/>}
                                            label={user.email ?? '-'}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <div className="SubmittedSongs"></div>

                <div className="PendingVideos"></div>
            </React.Fragment>
        </PromotionDiv>
    );
};

export default User;

const IphoneToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
  }

  .b {
    display: block;
  }

  .toggle {
    width: 50px;
    height: 25px;
    border-radius: 100px;
    background-color: #ddd;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
  }

  .check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
  }

  .check:checked ~ .track {
    box-shadow: inset 0 0 0 20px #00c9c9;
  }

  .check:checked ~ .switch {
    right: 2px;
    left: 32px;
    transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0.05s, 0s;
  }

  .switch {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 32px;
    background-color: #fff;
    border-radius: 36px;
    z-index: 1;
    transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, 0.05s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .track {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
  }
`;


const StyledUIButton = styled(Button)`
  margin-top: 2rem !important;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100% !important;
`;

const ApprovedPromoDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const UserPromosDiv = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PromotionDiv = styled.div`
  margin: 2rem 2rem 0rem 2rem;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  .UserData {
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .UpdateForm {
    display: flex;
    flex-direction: column;

    input {
      margin: 0.5rem;
    }

    select {
      margin: 0.5rem;
    }
  }

  .TogglableHeaders {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 2rem;
    width: 50%;
  }
`;

const DeleteButton = styled(Button)`
  margin-block: 0 !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  white-space: nowrap;
  height: 42px !important;
  width: 150px !important;
  background-color: black !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  font-family: "IBM Plex Sans Regular" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: small !important;
  line-height: 26px !important;
`;

const HeaderChip = styled(Chip)`
  background-color: #b1b1b1 !important;
  border-radius: unset !important;
  height: 42px !important;
  font-family: "IBM Plex Sans Regular" !important;
  font-style: normal !important;
  font-size: 1.1rem !important;
  line-height: 26px !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  //margin-right: 1.5rem;
`;

const HeaderChip2 = styled(HeaderChip)`
  font-weight: 700 !important;
`;

const LinkButton = styled(Button)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  white-space: nowrap;
  height: 28px !important;
  width: 130px !important;
  font-family: "IBM Plex Sans Regular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: x-small !important;
  line-height: 26px !important;
`;