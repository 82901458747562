import React, {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Musician from "src/components/admin-components/Musician";
import styled from "styled-components";
import {Pagination} from "react-pagination-bar";
import {useHistory, useLocation} from "react-router-dom";
import {AddToast} from "../../actions/SetToastsAction";

const USERS_PER_PAGE = 20;

const Musicians = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const accessToken = useSelector((state) => state.user.access_token, shallowEqual);

    const [musicians, setMusicians] = useState([]);
    const [totalMusicianCount, setTotalMusicianCount] = useState(0);

    const searchParams = new URLSearchParams(location.search);
    const {page} = Object.fromEntries(searchParams.entries());
    const currentPage = parseInt(page, 10) || 1;

    const handlePageChange = useCallback((pageNumber) => {
        history.push(`?page=${pageNumber}`);
    }, [history]);

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                page: currentPage,
                per_page: USERS_PER_PAGE
            }
        };

        axios.get('/api/admin/musicians', config)
            .then(res => {
                setMusicians(res.data.data.items);
                setTotalMusicianCount(res.data.data._meta.total_items);
            })
            .catch(err => {
                dispatch(AddToast({
                    message: err.response?.data?.message ?? "Something went wrong",
                    type: "error",
                    color: "#F06666"
                }));
            });
    }, [accessToken, currentPage, dispatch]);


    return (
        <div>
            <h1 style={{marginLeft: "2rem"}}>
                Musicians
            </h1>
            {musicians.map((musician) => {
                return <Musician key={musician.id} musician={musician} />;
            })}

            {
                totalMusicianCount !== 0 &&
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2rem",
                    }}
                    className={"PaginationB"}
                >
                    <StyledMyPagination
                        initialPage={currentPage}
                        itemsPerPage={USERS_PER_PAGE}
                        onPageСhange={handlePageChange}
                        pageNeighbours={2}
                        totalItems={totalMusicianCount}
                        customClassNames={{
                            rpbItemClassName: "custom-item",
                            rpbItemClassNameActive: "custom-item--active",
                            rpbGoItemClassName: "custom-go-item",
                            rpbItemClassNameDisable: "custom-item--disable",
                            rpbRootClassName: "custom-root",
                        }}
                    />
                </div>
            }
        </div>
    );
}

const StyledMyPagination = styled(Pagination)`
  .custom-item {
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    cursor: pointer;
    background-color: #180139;
    color: #fff;
  }

  .custom-item--active {
    background-color: rgb(104, 0, 255);
    border-radius: 50px;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .custom-item--disable {
    opacity: 0.5;
  }

  .custom-root ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .custom-go-item {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-inline-end: 0.75rem;
    padding-inline-start: 0.75rem;
    width: 150px;
  }

  .custom-go-item input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 40px;
    width: 100%;
  }

  .custom-progress-bar {
    background-color: #9e75ff;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
    height: 3px;
    margin: 1rem 0;
    max-width: 100%;
    transition: width 0.2s ease;
  }
`;


export default Musicians;