const initState = {
    info: {},
};

const LevelInformationReducer = (state=initState, action) =>{
    switch(action.type){
        case "SET_LEVEL_INFORMATION":
            // console.log(action.payload.info)
            return{
                info: action.payload.info
            };
        default:
            return{...state}
    }
};

export default LevelInformationReducer;