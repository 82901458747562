import styled from "styled-components";
import {Pagination} from "react-pagination-bar";

export const  BackButton = styled.button`
    margin-bottom: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: bold;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: none;
  font-family: "IBM Plex Sans Regular";
`;

export const PaymentInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  input {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-top: 0.5rem;
    border: 2px solid #000000;
    border-radius: 4px;
    font-family: "IBM Plex Sans Regular";
    font-size: 1rem;
  }
  select {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-top: 0.5rem;
    border: 2px solid #000000;
    background-color: white;
    border-radius: 4px;
    font-family: "IBM Plex Sans Regular";
    font-size: 1rem;
  }
  .SubmitButton {
    cursor: pointer;
  }
`;

export const StyledSubmit = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: black;
  color: white;
  font-family: "IBM Plex Sans Regular",serif;
  font-size: 1.25rem;
  font-weight: bold;
`;

export const StyledPagination = styled(Pagination)`
    .custom-root {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    
    .custom-item {
        border-radius: 50px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        min-width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        cursor: pointer;
        background-color: #180139;
        color: #fff;
    }

    .custom-item--active {
        background-color: rgb(104, 0, 255);
        border-radius: 50px;
        color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        min-width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }

    .custom-item--disable {
        opacity: 0.5;
    }
    
    .custom-root ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .custom-go-item {
        align-items: center;
        display: flex;
        font-size: 1rem;
        padding-inline-end: 0.75rem;
        padding-inline-start: 0.75rem;
        width: 150px;
    }

    .custom-go-item input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 40px;
        width: 100%;
    }

    .custom-progress-bar {
        background-color: #9e75ff;
        border-radius: 5px;
        box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
        height: 3px;
        margin: 1rem 0;
        max-width: 100%;
        transition: width 0.2s ease;
    }
`;

export const BlackButton = styled.button`
    font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
    background-color: black;
    color: white;
    white-space: nowrap;
    cursor: pointer;

    border: none;
    border-radius: 4px;
    padding: ${({ padding }) => padding || '0.8rem 4rem'};
`;
