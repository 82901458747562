const initState = {
    info: {},
};

const CampaignReducer = (state=initState, action) =>{
    switch(action.type){
        case "SET_CURRENT_CAMPAIGN":
            return{
                info: action.payload.campaign
            };
        default:
            return{...state}
    }
};

export default CampaignReducer;