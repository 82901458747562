import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { DeleteToast } from "src/actions/SetToastsAction";
import { motion, AnimatePresence } from "framer-motion";

export const Toast = ({ id, toast }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(DeleteToast(id));
    }, 5000);
  }, []);
  return (
    <ToastDiv
      color={toast.color}
      key={id}
      initial={{ opacity: 0, y: "-100%" }}
      animate={{ opacity: 1, y: "0%" }}
      exit={{ opacity: 0.2, y: "-100%", transition: { duration: 0.2 } }}
    >
      <p>{toast.message}</p>
    </ToastDiv>
  );
};

export const Toasts = () => {
  const toasts = useSelector((state) => state.Toasts.ToastMessages);
  return (
    <ToastList>
      <AnimatePresence>
        {toasts.map((toast) => (
          <Toast id={toast.id} key={toast.id} toast={toast} />
        ))}
      </AnimatePresence>
    </ToastList>
  );
};

const ToastList = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100vw;
  left: 0;
  z-index: 10000;
`;

const ToastDiv = styled(motion.div)`
  width: 70%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: 0.5rem 0rem 0rem 0rem;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  color: white;
  font-family: "IBM Plex Sans Regular";
  font-weight: bold;
  font-size: 1rem;
`;
