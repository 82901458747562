import React from 'react';
import './errorStyle.css';
import { Link } from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";

const PageNotFound = () => {
    const user = useSelector((state) => state.user.info, shallowEqual);

    const homePage = user.user_type === 'musician' ? process.env.REACT_APP_MUSICIANS_HOMEPAGE :
        process.env.REACT_APP_CREATORS_HOMEPAGE;

    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>4<span></span>4</h1>
                </div>
                <h2>Oops! Page Not Be Found</h2>
                <p>Sorry but the page you are looking for does not exist, have been removed, or is temporarily unavailable</p>
                <Link to={homePage}>Back to homepage</Link>
            </div>
        </div>
    );
};

export default PageNotFound;