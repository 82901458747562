import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from "styled-components";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {AddToast, DeleteToast} from "src/actions/SetToastsAction";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const BackgroundProcesses = () => {
    const dispatch = useDispatch();
    const access_token = useSelector(state => state.user.access_token);
    const [scheduledTasks, setScheduledtasks] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const config = {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
        params: {man_verification: "True"}
    };

    function createData([id, description, meta]) {
        return {id, description, meta};
    }

    const getAllScheduledTasks = () => {
        axios.get("/api/schedule/get_scheduled_tasks", config)
            .then((res) => {
                let rows = []
                res.data.scheduled_tasks.forEach((scheduledTask) => {
                    rows.push(createData(scheduledTask))
                })
                setScheduledtasks(rows)
            })
            .catch((e) => {
                dispatch(AddToast({message: e.message, type: "error", color: "#F06666"}));
            })
    };

    useEffect(() => {
        getAllScheduledTasks()
    }, [])

    return (
        <>
            <div className="table">
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Task ID</strong></TableCell>
                                <TableCell><strong>Description</strong></TableCell>
                                <TableCell><strong>Metadata&nbsp;</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {scheduledTasks.map((task) => (
                                <TableRow
                                    key={task.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {task.id}
                                    </TableCell>
                                    <TableCell>{task.description}</TableCell>
                                    <TableCell>{JSON.stringify(task.meta)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default BackgroundProcesses;

