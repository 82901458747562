export const AddToast = (toast) => (dispatch) =>{
    dispatch({
        type: "ADD_MESSAGE",
        payload: {
            message:toast
        }
    })
};

export const DeleteToast = (id) => (dispatch) =>{
    dispatch({
        type: "DELETE_MESSAGE",
        payload: {
            id: id
        }
    })
};