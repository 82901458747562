import AWS from 'aws-sdk';
import {AddToast} from "../../actions/SetToastsAction";

const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
const FOLDER_NAME = process.env.REACT_APP_S3_FOLDER_NAME;
const REGION = process.env.REACT_APP_S3_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const SECRET_KEY = process.env.REACT_APP_S3_SECRET_KEY;

const generateFileName = (file) => {
    const path = require('path');
    const crypto = require('crypto');

    const uuid = crypto.randomBytes(16).toString("hex");

    // Extract the base name without extension and the file extension
    const fileNameWithoutExtension = path.basename(file.name, path.extname(file.name));
    const fileExtension = path.extname(file.name);

    // Define the maximum length for the file name
    const maxFileNameLength = 80;

    // Calculate the maximum length for the base name part to avoid exceeding the total max length
    const maxBaseNameLength = maxFileNameLength - (uuid.length + fileExtension.length + 1); // +1 for the underscore

    // Truncate the base name if necessary
    const truncatedBaseName = fileNameWithoutExtension.length > maxBaseNameLength
        ? fileNameWithoutExtension.slice(0, maxBaseNameLength)
        : fileNameWithoutExtension;

    return `${truncatedBaseName}_${uuid}${fileExtension}`;
}

const uploadFilesToS3 = async (files) => {
    AWS.config.update({
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_KEY,
    });

    const s3 = new AWS.S3({
        params: {Bucket: S3_BUCKET_NAME},
        region: REGION,
    });

    try {
        const uploadPromises = files.map(file => {
            const newFileName = generateFileName(file);

            const params = {
                Bucket: S3_BUCKET_NAME,
                Key: `${FOLDER_NAME}/${newFileName}`,
                Body: file,
            };

            s3.putObject(params).promise().then(() => newFileName);

            return newFileName;
        });

        const uploadedFilesUrls = await Promise.all(uploadPromises);

        return {
            success: true,
            message: "All files uploaded successfully.",
            urls: uploadedFilesUrls,
        };
    } catch (error) {
        return {
            success: false,
            message: "Uploading files to S3 has failed . Please try again.",
            error: error,
        };
    }
};

export const uploadFilesAndUpdatePayload = async (imageFile, songFile, payload, dispatch) => {
    let files = [];
    let fileTypes = []; // Tracks the type of each file being uploaded

    const maxFileSize = 15 * 1024 * 1024; // 15 MB in bytes

    if (imageFile?.size > maxFileSize || songFile?.size > maxFileSize) {
        dispatch(
            AddToast({
                message: "One or both files exceed the 15 MB size limit",
                type: "error",
                color: "#F06666",
            })
        );

        return null;
    }

    [imageFile, songFile].forEach((file, index) => {
        if (file) {
            files.push(file);
            fileTypes.push(index === 0 ? 'imageFile' : 'songFile');
        }
    });

    try {
        const uploadResult = await uploadFilesToS3(files);

        console.log("uploadResult", uploadResult);

        if (!uploadResult.success) {
            dispatch(AddToast({
                message: uploadResult.message ?? "Error, contact support!",
                type: "error",
                color: "#F06666",
            }));
            return null; // Indicate failure by returning null
        }

        uploadResult.urls.forEach((url, index) => {
            if (fileTypes[index] === 'imageFile') {
                payload.song.album_icon_url = url;
            } else if (fileTypes[index] === 'songFile') {
                payload.song.google_drive_url = url;
            }
        });

        return payload; // Return the updated payload upon success
    } catch (error) {
        console.error("An error occurred during file upload:", error);
        dispatch(AddToast({
            message: "Unexpected error during upload. Please try again or contact support.",
            type: "error",
            color: "#F06666",
        }));
        return null; // Indicate failure by returning null
    }
}


