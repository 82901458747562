import React, {useEffect, useState} from "react";
import styled from "styled-components";
import account_img from "../images/account.png";
import account_img_white from "../images/account_img_white.png";
import credits from "../images/credits.png";
import credits_white from "../images/credits_white.png";
import promotion_img from "../images/promotion.png";
import promotion_img_white from "../images/promotion_white.png";
import logo_img from "../images/logo.svg";
import account_black from "../images/account_black.png";
import Logout from "../images/logut.png";
import {useScrollPosition} from "../hooks/useScrollPosition";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import firebase from 'firebase/compat/app';
import "firebase/auth";
import {Link, useRouteMatch, useHistory} from "react-router-dom";
import { checkIfAdmin} from "../utils/utils";
import {logout} from "src/services/firebase/auth";

const Nav = () => {
    let {path, url} = useRouteMatch();
    const dispatch = useDispatch();

    const activePage = useSelector((state) => state.user.active_page, shallowEqual);
    const accountInfo = useSelector((state) => state.user.info, shallowEqual);

    const [backgroundColor, setBackgroundColor] = useState("");
    const [backgroundOpacity, setBackgroundOpacity] = useState(1);
    const [isAdmin, setIsAdmin] = useState(false);

    function setHome() {
        window.location = "https://muvid.de/";
    }

    const setActiveDiv = (div) => {
        dispatch({
            type: "SET_ACTIVE_PAGE",
            payload: div,
        });
    };

    const navItems = {
        creator: [
            { name: "account", icon: { white: account_img_white, default: account_img } },
            { name: "campaigns", icon: { white: promotion_img_white, default: promotion_img } },
            { name: "credits", icon: { white: credits_white, default: credits } },
        ],
        musician: [
            { name: "account", icon: { white: account_img_white, default: account_img } },
            { name: "musician-campaigns", icon: { white: promotion_img_white, default: promotion_img } },
        ]
    };

    const navItemsBasedOnUserType = navItems[accountInfo.user_type] || [];

    useScrollPosition(({prevPos, currPos}) => {
        if (currPos.y > -100) {
            setBackgroundColor("");
        }
        if (currPos.y < -20) {
            setBackgroundColor("white");
            if (currPos.y < prevPos.y) {
                setBackgroundOpacity(0);
            }
        }

        if (currPos.y > prevPos.y) {
            setBackgroundOpacity(1);
        }
    });

    useEffect( () => {
        const fetchAdminStatus = async () => {
            const isAdminStatus = await checkIfAdmin(firebase.auth().currentUser);
            setIsAdmin(isAdminStatus);
        };

        fetchAdminStatus();
    }, [])

    const MenuItem = ({ name, icon, setActiveDiv, activePage, url }) => {
        const isActive = activePage === name;
        const bgColorDiv = isActive ? "#00CCCC" : "";
        const textColor = isActive ? "white" : "black";
        const iconSrc = isActive ? icon.white : icon.default;
        const itemName = name === 'musician-campaigns' ? "Campaigns" :name.charAt(0).toUpperCase() + name.slice(1);

        return (
            <StyledLink
                bg_color_div={bgColorDiv}
                text_color={textColor}
                onClick={() => setActiveDiv(name)}
                to={`${url}/${name}`}
            >
                <NavItem>
                    <AccountIcon src={iconSrc} />
                    <p>{itemName}</p>
                </NavItem>
            </StyledLink>
        );
    };

    return (
        <>
            <MobileNav>
                {navItemsBasedOnUserType.map(item => (
                    <MenuItem
                        key={item.name}
                        name={item.name}
                        icon={item.icon}
                        setActiveDiv={setActiveDiv}
                        activePage={activePage}
                        url={url}
                    />
                ))}
            </MobileNav>
            <DesktopNav bg_color={backgroundColor} Opacity={backgroundOpacity} className="DesktopNav">
                <img style={{ cursor: "pointer" }} onClick={() => setHome()} src={logo_img} className="Logo" />
                <div>
                    {navItemsBasedOnUserType.map(item => (
                        <MenuItem
                            key={item.name}
                            name={item.name}
                            icon={item.icon}
                            setActiveDiv={setActiveDiv}
                            activePage={activePage}
                            url={url}
                        />
                    ))}
                    {isAdmin && (
                        <StyledLink bg_color_div={""} text_color={"black"} to={`/admin`}>
                            <div>
                                <img src={account_black} />
                                <p>Admin</p>
                            </div>
                        </StyledLink>
                    )}
                    <StyledLogout onClick={(e) => logout(e)}>
                        <img src={Logout} />
                        <p>Logout</p>
                    </StyledLogout>
                </div>
            </DesktopNav>
        </>
    );
};
const StyledLogout = styled.div`
  text-decoration: none;
  background-color: ${(props) => props.bg_color_div};
  padding: 1rem 0rem 1rem 0rem;
  margin-top: 1rem;

  div {
  }

  color: black;
  font-weight: 700;
  flex-grow: 1;
  width: 33%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  background-color: ${(props) => props.bg_color_div};
  padding: 1rem 0 1rem 0;
  margin-top: 0;

  color: ${(props) => props.text_color} !important;
  font-weight: 700;
  flex-grow: 1;
  width: 33%;
  //color: #000000;

  @media only screen and (min-width: 768px) {
    width: 82px !important;
      //height: auto !important;
    padding: 1rem 0;
    margin-top: 1rem;
  }
`;
const DesktopNav = styled.nav`
  position: fixed;
  display: flex;
  padding-top: 2rem;
  padding-left: 2rem;
  justify-content: space-between;
  width: 100vw;
  z-index: 5000;
  background-color: ${(props) => props.bg_color};
  opacity: ${(props) => props.Opacity};
  top: 0;

  div {
    display: flex;
    padding: 0rem 1rem 0rem 1rem;
    cursor: pointer;
    align-items: center;

    p {
      font-size: 0.7rem;
      font-weight: bold;
    }

    div {
      display: flex;
      flex-direction: column;

      img {
        padding: 0rem;
        z-index: 5000;
        height: 1.5rem;
      }
    }
  }

  img {
    height: 4rem;
    padding: 1rem;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileNav = styled.nav`
  @media only screen and (min-width: 768px) {
    display: none;
  }
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  justify-content: space-between;
  width: 100vw;
  background-color: white;
  box-shadow: 0px 8px 10px rgba(0, 203, 205, 0.2);
  font-size: 1.3rem;
  z-index: 100;
`;

const NavItem = styled.div`
  flex-grow: 1;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0;
`;
const AccountIcon = styled.img`
  width: 1.5rem;
  height: 2rem;
  pointer-events: none;
  user-select: none;
`;

export default Nav;
