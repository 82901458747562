import React, { useEffect, useState } from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { Toasts } from "../components/Toasts";
import Dashboard from "../pages/admin-pages/Dashboard";
import { auth } from "../services/firebase/auth";
import { loadUserInformation } from "../actions/userAction";
import { checkIfAdmin } from "../utils/utils";
import CreateCampaign from "../pages/admin-pages/CreateCampaign";
import CreateMusician from "../pages/admin-pages/CreateMusician";
import AdminCampaigns from "../pages/admin-pages/AdminCampaigns";
import Creators from "../pages/admin-pages/Creators";
import Invoices from "../pages/admin-pages/Invoices";
import BackgroundProcesses from "../pages/admin-pages/BackgroundProcesses";
import {setCurrencyAction} from "../actions/setCurrencyAction";
import PageNotFound from "../pages/error-pages/PageNotFound";
import Musicians from "src/pages/admin-pages/Musicians";

const routes = [
    { path: "/create-campaign", component: CreateCampaign},
    { path: "/create-musician", component: CreateMusician},
    { path: "/campaigns", component: AdminCampaigns },
    { path: "/campaigns/active", component: AdminCampaigns },
    { path: "/campaigns/archived", component: AdminCampaigns },
    { path: "/creators", component: Creators},
    { path: "/invoices", component: Invoices},
    { path: "/background-processes", component: BackgroundProcesses},
    { path: "/musicians", component: Musicians },
];

const AdminRouter = () => {
    const dispatch = useDispatch();

    const [userFire, loading] = useAuthState(auth);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isAdminCheckComplete, setIsAdminCheckComplete] = useState(false);

    const userObj = useSelector((state) => state.user, shallowEqual);
    const user = userObj.info;
    const accessToken = userObj.access_token;

    const isUserLoaded = Object.keys(user).length > 0 && accessToken !== null;

    useEffect(() => {
        if (!userFire || loading) return;

        if (!isUserLoaded) {
            dispatch(loadUserInformation(userFire, userFire.accessToken))
        }

        dispatch(setCurrencyAction('€'));
    }, [dispatch, isUserLoaded, loading, userFire]);

    useEffect(() => {
        if (userFire && !loading) {
            checkIfAdmin(auth.currentUser)
                .then(isAdminResult => {
                    setIsAdmin(isAdminResult);
                })
                .catch(() => {
                    setIsAdmin(false);
                })
                .finally(() => {
                    setIsAdminCheckComplete(true);
                });
        }
    }, [loading, userFire]);

    if (!isAdminCheckComplete || !isUserLoaded) {
        return null;
    }

    return isAdmin ? (
        <>
            <Toasts />
            <Dashboard routes={routes}/>
        </>
    ) : <PageNotFound/>;
};

export default AdminRouter;