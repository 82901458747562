const initState = {
    currency: {},
};

const CurrencyReducer = (state=initState, action) =>{
    switch(action.type){
        case "SET_CURRENCY":
            return{
                currency: action.payload.currency
            };
        default:
            return{...state}
    }
};

export default CurrencyReducer;