import React from 'react';
import {AppBar, Toolbar, Typography, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Home";
import {useHistory} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";

const drawerWidth = 265;

export default function AdminNav({isDrawerOpen, setIsDrawerOpen}) {
    const history = useHistory();
    const classes = useStyles();

    const user = useSelector((state) => state.user.info, shallowEqual);

    const homePage = user.user_type === 'musician' ? process.env.REACT_APP_MUSICIANS_HOMEPAGE :
        process.env.REACT_APP_CREATORS_HOMEPAGE;

    return (
        <AppBar
            position="absolute"
            className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}
        >
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setIsDrawerOpen(true)}
                    className={clsx(
                        classes.menuButton,
                        isDrawerOpen && classes.menuButtonHidden
                    )}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                >
                    Admin Panel
                </Typography>

                <IconButton onClick={e => history.push(homePage)}>
                    <DeleteIcon fontSize="large"/>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: "#00CCCC",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
}));
