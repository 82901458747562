import React, {useState, useEffect} from 'react';

const FileInputComponent = ({defaultFileName = '', fileType, inputName, onFileChange}) => {
    const [fileName, setFileName] = useState(defaultFileName);

    useEffect(() => {
        // Update the fileName state if the defaultFileName prop changes
        setFileName(defaultFileName);
    }, [defaultFileName]);

    const handleChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name); // Update the fileName state to the selected file's name
            onFileChange(file); // Lift the file data up to the parent component
        } else {
            setFileName(defaultFileName); // Reset to default if no file is selected
            onFileChange(null); // Notify parent component that no file is selected
        }
    };

    return (
        <div style={{margin: "0 0.5rem 1rem 0.5rem"}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                <button type="button" onClick={() => document.getElementById(`${inputName}-fileInput`).click()}
                style={{
                    padding: '0.5rem 1rem',
                    margin: 0,
                    width: '8rem'
                }}>
                    Choose File
                </button>
                <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {fileName || 'No file chosen'}
                </div>
            </div>
            <input
                id={`${inputName}-fileInput`}
                name={inputName}
                type="file"
                accept={fileType}
                style={{ display: 'none' }}
                onChange={handleChange}
            />
        </div>
    );
};

export default FileInputComponent;