import Nav from "./Nav";
import {Toasts} from "./Toasts";
import logo_img from "../images/logo.svg";
import ScrollToTop from "../utils/ScrollToTop";
import React from "react";
import TiktokConnectionModal from "./influencer-components/pop-ups/TiktokConnectionModal";
import styled from "styled-components";

const RouterStaticContent = ({showVerifyTiktokDiv}) => {

    return (
        <>
            <Nav/>
            <Toasts/>
            {showVerifyTiktokDiv === 1 && (
                <>
                    <TiktokConnectionModal/>
                    <BackgroundDim/>
                </>
                )
            }
            <BackgroundGradient/>
            <BackgroundGradient2/>
            <BackgroundGradient3/>
            <LogoWrapper>
                <LogoStyled src={logo_img} onClick={() => window.location.href = "https://muvid.de/"}/>
            </LogoWrapper>
            <ScrollToTop/>
        </>
    )
}

const BackgroundDim = styled.div`
  display: ${(props) => props.DISPLAY};
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  z-index: 10000;
`;

const LogoStyled = styled.img`
    width: 5.94rem;
    height: auto;
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 1rem;
    @media only screen and (min-width: 768px) {
        display: none;
    }
`;

const BackgroundGradient = styled.div`
    position: absolute;
    top: -62.39px;
    left: -114.34px;
    width: 414.74px;
    height: 414.74px;
    background: radial-gradient(50% 50% at 50% 50%,
    rgba(10, 204, 194, 0.37) 0%,
    rgba(10, 204, 199, 0) 100%);
    z-index: -1;
    overflow: hidden;
`;
const BackgroundGradient2 = styled.div`
    position: absolute;
    top: 30%;
    left: -79px;
    width: 414.74px;
    height: 414.74px;
    background: radial-gradient(50% 50% at 50% 50%,
    rgba(10, 204, 194, 0.37) 0%,
    rgba(10, 204, 199, 0) 100%);
    z-index: -1;
    overflow: hidden;
`;

const BackgroundGradient3 = styled.div`
    position: fixed;
    top: 10%;
    left: 50%;
    width: 50%;
    height: 20%;
    background: radial-gradient(50% 50% at 50% 50%,
    #ffdf2b 0%,
    rgba(255, 223, 43, 0) 100%);
    z-index: -1;
    opacity: 0.3;
    overflow: hidden;
`;



export default RouterStaticContent;