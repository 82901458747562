const checkIfAdmin = async (user) => {
    const idTokenResult = await user.getIdTokenResult();
    return !!idTokenResult.claims.admin;
};

const convertDateTimeToLocalFormat = (dateTimeValue) => {
    const inputDate = new Date(dateTimeValue);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const hours = String(inputDate.getHours()).padStart(2, '0');
    const minutes = String(inputDate.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:00`;
};

function formatToDateTimeUTC(isoString) {
    return isoString.replace('T', ' ').substring(0, 19); // ISO string format is consistent, so 19 characters are safe to use
}

const roundNumber = (num, scale) => {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = "";
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(
            Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
            "e-" +
            scale
        );
    }
}

const generateAccountLevelInformation = (user, levelInfo, currency) => {
    let img, nextLevelText, currentLevelText;
    const userLevel = user.creator?.level;
    const level = userLevel?.name;
    const levelType = userLevel?.type;

    if (levelType === 'normal') {
        const currentLevel = level !== undefined ? parseInt(level, 10) : 0;
        const nextLevel = currentLevel === 0 ? 6 : currentLevel === levelInfo.length - 1 ? currentLevel : currentLevel + 1;

        img = currentLevel !== 0 ? `/images/level_${currentLevel}.png` : '/images/nullimg.png';

        nextLevelText = nextLevel === currentLevel ?
            'You have reached the highest Level!' :
            `Level up to earn up to ${currency}${levelInfo[nextLevel].max_amount_eur} per Video!`;

        currentLevelText = level !== '0' ?
            `Earn up to ${currency}${levelInfo[currentLevel].max_amount_eur} per Video!` :
            'So far your TikTok profile is still too small to earn money. But feel free to use muvid to discover new songs, get ideas for videos or support upcoming artists.';

        return {img, nextLevelText, currentLevelText};
    }

    currentLevelText = 'Based on your tiktok profile and your performance we settled a fixed deal'
    nextLevelText = levelType === 'linear' ?
        `You earn: ${currency}${userLevel?.per_1k_views_amount_eur} per 1.000 views within first 72 hours after your video was posted. Limit per Video is ${currency}${userLevel?.max_amount_eur}. `
        : `You earn: ${currency}${userLevel?.max_amount_eur} per Video. `
    nextLevelText += 'All other requirements and T&Cs apply.'

    return {img: '/images/handshake.png', nextLevelText, currentLevelText};
};

export {convertDateTimeToLocalFormat, roundNumber, generateAccountLevelInformation, checkIfAdmin, formatToDateTimeUTC};
