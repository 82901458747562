import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import AdminRouter from "./AdminRouter";
import Signup from "../pages/Signup";
import AppRouter from "./AppRouter";
import ActualLoginPage from "../pages/LoginPage";
import PageNotFound from "../pages/error-pages/PageNotFound";

const BaseRouter = () => {
    const location = useLocation();

    return (
        <Switch location={location} key={location.pathname}>
            <Route path="/" exact>
                {() => window.location = 'https://muvid.de'}
            </Route>
            <Route path="/app">
                <AppRouter/>
            </Route>
            <Route path="/admin">
                <AdminRouter/>
            </Route>
            <Route path="/signup" exact>
                <Signup/>
            </Route>
            <Route path="/login" exact>
                <ActualLoginPage/>
            </Route>
            <Route path="*" exact>
                <PageNotFound/>
            </Route>
        </Switch>
    );
};

export default BaseRouter;