import React, {useEffect, useMemo, useState} from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {DataGrid} from '@mui/x-data-grid';
import {motion} from "framer-motion";
import {getAdminBillsApiCall, downloadBillPdfApiCall} from "src/utils/apiCalls";

const Invoices = () => {
    const dispatch = useDispatch();

    const currency = useSelector((state) => state.currency.currency, shallowEqual);
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);

    const [bills, setBills] = useState([]);
    const [metaData, setMetadata] = useState({});
    const [rowCountState, setRowCountState] = useState(
        metaData.total_items || 0,
    );
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25
    });

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        };
    }, [access_token]);

    const columns = [
        {field: "bill_number", headerName: "Invoice", width: "250"},
        {field: "lastname", headerName: "Lastname", valueGetter: params => params.row.user.last_name, width: "150"},
        {
            field: "tiktokName",
            headerName: "Tiktok username",
            valueGetter: params => params.row.creator.tiktok_username,
            width: "150"
        },
        {field: "amount_eur", headerName: "Amount", valueFormatter: ({value}) => value + currency, width: "100"},
        {field: "created_at", headerName: "date", width: "200"},
        {field: "status", headerName: "Status", width: "200"},
        {
            field: "download", headerName: "Download", renderCell: (params) => {
                return <StyledButton
                    onClick={() => downloadBillPdfApiCall(params.row.id, {...config, responseType: "blob"}, dispatch)}
                    variant="contained"
                    color="primary"
                >
                    Download
                </StyledButton>
            }, width: "200"

        }
    ];

    useEffect(() => {
        const newConfig = {
            ...config,
            params: {page: paginationModel.page + 1, per_page: paginationModel.pageSize},
        }

        getAdminBillsApiCall(newConfig, dispatch, (res) => {
            setBills(res.data.data.items);
            const _meta = res.data.data._meta;

            setMetadata(res.data.data._meta)
            setRowCountState((prevRowCountState) =>
                _meta?.total_items !== undefined
                    ? _meta.total_items
                    : prevRowCountState,
            );
        })
    }, [config, paginationModel.page, paginationModel.pageSize])


    return (
        <StyledDiv>
            <div style={{height: '85vh', width: '100%'}} className={"parent"}>
                <DataGrid
                    rows={bills}
                    columns={columns}
                    rowCount={rowCountState}
                    pageSizeOptions={[
                        {value: 25, label: '25'},
                        {value: 50, label: '50'},
                        {value: rowCountState, label: 'All'}
                    ]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                />
            </div>
        </StyledDiv>
    );
};

export default Invoices;

const StyledButton = styled(Button)``;

const StyledDiv = styled(motion.div)`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }
`;