import {shallowEqual, useDispatch, useSelector} from "react-redux";
import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import closeBTN from "../../images/reward model icons/modal/close.png";
import Chip from "@material-ui/core/Chip";
import styled from "styled-components";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import updatePromotionVideoLink from '../../utils/updatePromotionVideoLink'
import {deletePromotionApiCall, updatePromotionApiCall} from "src/utils/apiCalls"

const SubmittedPromo = ({promo}) => {
    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);
    const [deleteText, setDeleteText] = useState("Delete");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isValidFormat, setIsValidFormat] = useState(true);
    const inputDateRef = useRef(null);

    const [values, setValues] = useState({
        Views: '',
        Likes: '',
        Comments: '',
        Shares: '',
    });

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const config = {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const updatePromotionStatusAndVideoUploadDate = (status) => {
        let inputDate =inputDateRef.current.value
        const formatRegex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2]\d|3[0-1]) (0\d|1\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

        if (formatRegex.test(inputDate)) {
            const [year, month, day, hour, minute, second] = inputDate.split(/[- :]/);
            const currentDate = new Date();

            const isValidYear = parseInt(year) === currentDate.getFullYear();
            const isValidMonth = parseInt(month) >= 1 && parseInt(month) <= 12;
            const isValidDay = parseInt(day) >= 1 && parseInt(day) <= 31;
            const isValidHour = parseInt(hour) >= 0 && parseInt(hour) <= 23;
            const isValidMinute = parseInt(minute) >= 0 && parseInt(minute) <= 59;
            const isValidSecond = parseInt(second) >= 0 && parseInt(second) <= 59;

            if (isValidYear && isValidMonth && isValidDay && isValidHour && isValidMinute && isValidSecond) {
                setIsValidFormat(true);
                inputDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            }
        } else {
            return setIsValidFormat(false);
        }

        let payload = {
            status: status,
        };

        if (status === "approved") {
            payload = {
                ...payload,
                video_uploaded_at: isValidFormat && inputDate,
                promotion_stat: {
                    views_count: values.Views,
                    likes_count: values.Likes,
                    comments_count: values.Comments,
                    shares_count: values.Shares,
                }
            };
        }

        updatePromotionApiCall(promo.id, payload, config, dispatch, (res) => {
            window.setTimeout(() => {
                window.location.reload();
            }, 2000)
        });
    };

    return (
        <>
            <MODALE
                DISPLAY={isModalOpen ? "none" : "flex"}
                style={{flexDirection: "column", alignItems: "center"}}
            >
                <div
                    style={{
                        height: "10%",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <h3 style={{ fontSize: "24px",  margin: "0 auto"}}>
                        Manual approve
                    </h3>
                    <img
                        onClick={toggleModal}
                        style={{
                            height: "100%",
                            cursor: "pointer",
                        }}
                        src={closeBTN}
                    />
                </div>

                <div>
                    {Object.keys(values).map((key) => (
                        <div
                            key={key}
                            style={{
                                marginTop: "1rem",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <p>{key}:</p>
                            <input
                                name={key}
                                placeholder={key.toLowerCase()}
                                onChange={handleInputChange}
                                style={{
                                    padding: "0.5rem",
                                    marginRight: "2rem",
                                    marginLeft: "0.5rem",
                                }}
                            />
                        </div>
                    ))}
                </div>

                <div
                    style={{
                        marginTop: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <p>Date:</p>
                    <input
                        placeholder={"YYYY-MM-DD HH:MM:SS"}
                        ref={inputDateRef}
                        style={{
                            padding: "0.5rem",
                            marginRight: "2rem",
                            marginLeft: "0.5rem",
                            border: isValidFormat ? "1px solid #ccc" : "1px solid red",
                        }}
                    />
                </div>
                {!isValidFormat && (
                    <p style={{color: "red"}}>
                        Please enter a valid date and time in the format: YYYY-MM-DD HH:MM:SS
                    </p>
                )}

                    <Button
                        style={{margin: 0, marginTop: "1rem"}}
                        variant="contained"
                        color="primary"
                        onClick={() => updatePromotionStatusAndVideoUploadDate("approved")}
                    >
                        Approve
                    </Button>
            </MODALE>
            <SubmitDiv>
                <div className="left">
                    <Chip
                        style={{
                            backgroundColor: "rgba(0, 203, 205, 0.01)",
                            marginRight: "3rem",
                        }}
                        className="chip"
                        icon={<AccountCircleOutlinedIcon/>}
                        label={promo.creator.tiktok_username}
                    />
                    <Chip
                        style={{
                            backgroundColor: "rgba(0, 203, 205, 0.01)",
                            marginRight: "3rem",
                        }}
                        className="chip"
                        icon={<EventOutlinedIcon/>}
                        label={promo.created_at}
                    />
                    <LinkButton
                        style={{backgroundColor: "black"}}
                        onClick={() => window.open(promo.video_url)}
                        variant="contained"
                        color="secondary"
                    >
                        <span>Videolink</span>
                    </LinkButton>
                </div>

                <div className="right">
                    <form style={{display: "block"}}
                          onSubmit={(e) => updatePromotionVideoLink(e, config, promo.id, dispatch)}>
                        <label>
                            <input type="text"
                                   name="videolink"
                                   placeholder="VideoLink"
                                   defaultValue={promo.video_url}
                            />
                        </label>
                        <LinkButton
                            style={{backgroundColor: "black"}}
                            variant="contained"
                            color="secondary"
                            type="submit"
                        >
                            <span>Edit link</span>
                        </LinkButton>
                    </form>
                    <LinkButton
                        style={{backgroundColor: "#00CCCC"}}
                        variant="contained"
                        color="primary"
                        onClick={() => toggleModal()}
                    >
                        Approve
                    </LinkButton>
                    <LinkButton
                        style={{backgroundColor: "#D32424"}}
                        variant="contained"
                        color="secondary"
                        onClick={() => updatePromotionApiCall(promo.id, {status: "declined"}, config, dispatch)}
                    >
                        Decline
                    </LinkButton>
                    <LinkButton
                        style={{backgroundColor: "#D32424"}}
                        variant="contained"
                        color="secondary"
                        onClick={deleteText === 'confirm' ? () => deletePromotionApiCall(promo.id, config, dispatch) : () => setDeleteText('confirm')}
                    >
                        {deleteText}
                    </LinkButton>
                </div>
            </SubmitDiv>
        </>
    );
};

export default SubmittedPromo;

const MODALE = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: ${(props) => props.DISPLAY};
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 2rem 3rem 2rem;
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 40%);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
  z-index: 99999999999;

  max-height: calc(100vh - 210px);
  overflow-y: auto;

  @media only screen and (max-width: 1024px) {
    width: 80%;
  }

  ul {
    list-style: square;
    margin-left: 2rem;
  }
`;

const SubmitDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  height: 43px;
  margin: auto;
  background-color: #00cbcd33;

  p {
    padding: 1rem;
  }

  .chip {
    margin: 1rem;
  }

  button {
    margin: 1rem 0.5rem !important;
    width: auto !important;
  }

  .right {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 0;
  }
`;

const LinkButton = styled(Button)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  white-space: nowrap;
  height: 28px !important;
  width: 130px !important;
  font-family: "IBM Plex Sans Regular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: x-small !important;
  line-height: 26px !important;
`;
