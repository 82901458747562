const initState = {
    campaigns: {},
};

const MusicianCampaignsReducer = (state=initState, action) =>{
    switch(action.type){
        case "SET_MUSICIAN_CAMPAIGNS":
            const campaignsData = action.payload;
            const currentPage = campaignsData.meta.page;
            // console.log(campaignsData, "campaignsData")

            return{
                ...state,
                meta: campaignsData.meta,
                campaigns: {
                    ...state.campaigns,
                    [currentPage]: campaignsData.campaigns
                }
            };
        default:
            return{...state}
    }
};

export default MusicianCampaignsReducer;