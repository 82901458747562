import {updatePromotionApiCall} from "./apiCalls";
import {AddToast} from "../actions/SetToastsAction";

const updatePromotionVideoLink = (e, config, promoId, dispatch, promotionStatus=null) => {
    e.preventDefault();
    let videoLink = e.target.videolink.value;

    if (videoLink !== null && /\S/.test(videoLink) && videoLink.includes("tiktok.com")) {
        const payload = {
            'video_url': videoLink,
            'status': promotionStatus
        };

        if (promotionStatus === null) {
            delete payload.status;
        }

        console.log(payload);

        updatePromotionApiCall(promoId, payload, config, dispatch, (res) => {
            window.setTimeout(() => {
                window.location.reload();
            }, 2000)
        });
    } else {
        dispatch(AddToast({
            message: "Please submit a Link", type: "error", color: "#F06666",
        }));
    }
}

export default updatePromotionVideoLink;