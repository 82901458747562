import axios from "axios";
import {AddToast} from "../actions/SetToastsAction";
import download from "downloadjs";

const getCampaignsApiCall = (config, dispatch, onSuccessCallback = null, onFinallyCallback=null) => {
    axios
        .get("/api/admin/campaigns", config)
        .then((res) => {
            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            return dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "There was an error",
                    type: "error",
                    color: "#F06666"
                })
            );
        })
        .finally(() => {
            if (onFinallyCallback && typeof onFinallyCallback === "function") {
                onFinallyCallback();
            }
        })
}

const getCampaignApiCall = (campaignId, config, dispatch, onSuccessCallback = null) => {
    axios
        .get(`/api/campaigns/${campaignId}`, config)
        .then((res) => {

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err.response?.data?.message ?? "Something went wrong, please contact support",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const createCampaignApiCall = (payload, config, dispatch, onSuccessCallback = null) => {
    axios
        .post("/api/admin/campaigns", payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Campaign created successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const updateCampaignApiCall = (campaignId, payload, config, dispatch, onSuccessCallback = null) => {
    axios
        .put(`/api/admin/campaigns/${campaignId}`, payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Campaign edited successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
};

const deleteCampaignApiCall = (campaignId, config, dispatch, onSuccessCallback = null) => {
    axios.delete(`/api/admin/campaigns/${campaignId}`, config)
        .then(res => {
            dispatch(
                AddToast({
                    message: "Campaign deleted successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support !",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
};

const getPromotionsApiCall = (campaignId, promotionType = null, config, dispatch, onSuccessCallback = null) => {
    let url;
    if (promotionType === null) {
        url = `/api/admin/campaigns/${campaignId}/promotions`;
    } else {
        url = `/api/admin/campaigns/${campaignId}/promotions?promotion_type=${promotionType}`;
    }

    axios.get(url, config)
        .then((res) => {
            // dispatch(
            //     AddToast({
            //         message: "Promotions fetched successfully!",
            //         type: "success",
            //         color: "#00CDA8",
            //     }));

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}

const updatePromotionApiCall = (promotionId, payload, config, dispatch, onSuccessCallback = null) => {
    axios.put(`/api/admin/promotions/${promotionId}`, payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: res.data.message,
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        }).catch(err => {
        switch (err.response.data.message) {
            case "profile does not match with video user name":
                dispatch(AddToast({
                    message: "The video was not uploaded to your TikTok profile. Please post the video on your linked TikTok profile. If you need help, please contact support.",
                    type: "error",
                    color: "#F06666",
                }));
                break;
            case "Could not confirm the usage of the right song in the provided video.":
                dispatch(AddToast({
                    message: "The video does not contain the correct sound. Please create a video with the appropriate sound. If you need help, please contact support.",
                    type: "error",
                    color: "#F06666",
                }));
                break;
            default:
                dispatch(AddToast({
                    message: err?.response?.data?.message, type: "error", color: "#F06666",
                }));
        }
    })
};

const deletePromotionApiCall = (promotionId, config, dispatch, onSuccessCallback = null) => {

    axios
        .delete(
            `/api/admin/promotions/${promotionId}`, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Promotion successfully deleted!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
};

const getAdminBillsApiCall = (config, dispatch, onSuccessCallback = null) => {
    axios.get("/api/admin/bills", config)
        .then((res) => {
            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const getCreatorBillsApiCall = (creatorId, config, dispatch, onSuccessCallback = null) => {
    axios.get(`/api/creators/${creatorId}/bills`, config)
        .then((res) => {
            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const updateBillApiCall = (billId, payload, config, dispatch, onSuccessCallback = null) => {
    axios
        .put(`/api/admin/bills/${billId}`, payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Invoice updated successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong",
                    type: "error",
                    color: "#F06666",
                })
            )
        });
};

const downloadBillPdfApiCall = (billId, config, dispatch, onSuccessCallback = null) => {
    axios
        .get(`/api/admin/bills/${billId}/pdf`, config)
        .then((res) => {
            const content = res.headers["content-type"];
            const filename_ = res.headers["content-disposition"].split("=")[1];
            download(res.data, filename_, content);

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Invoice PDF downloaded successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
};

const downloadCreatorBillPdfApiCall = (creatorId, billId, config, dispatch, onSuccessCallback = null) => {
    axios
        .get(`/api/creators/${creatorId}/bills/${billId}/pdf`, config)
        .then((res) => {
            const content = res.headers["content-type"];
            const filename_ = res.headers["content-disposition"].split("=")[1];
            download(res.data, filename_, content);

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Invoice PDF downloaded successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
};

const getUsersApiCall = (config, dispatch, onSuccessCallback = null) => {
    axios
        .get("/api/admin/users", config)
        .then((res) => {
            // dispatch(
            //     AddToast({
            //         message: "Campaigns fetched successfully!",
            //         type: "success",
            //         color: "#00CDA8",
            //     })
            // )

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            return dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "There was an error",
                    type: "error",
                    color: "#F06666"
                })
            );
        })
}

const deleteUserApiCall = (userId, config, dispatch, onSuccessCallback = null) => {
    axios.delete(`/api/admin/users/${userId}`, config)
        .then(res => {
            dispatch(
                AddToast({
                    message: res.data.message ?? "User deleted successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support !",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const updateUserApiCall = (userId, payload, config, dispatch, onSuccessCallback = null) => {
    axios
        .put(`/api/admin/users/${userId}`, payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "User edited successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const getAdminCreatorPromotionsApiCall = (creatorId, config, dispatch, onSuccessCallback = null) => {
    axios.get(`/api/admin/creators/${creatorId}/promotions`, config)
        .then((res) => {
            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}

const updateAdminCreatorApiCall = (creatorId, payload, config, dispatch, onSuccessCallback = null) => {
    axios.put(`/api/admin/creators/${creatorId}`, payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Creator edited successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}

const updateCreatorApiCall = (creatorId, payload, config, dispatch, onSuccessCallback = null, onFailureCallback = null) => {
    axios.put(`/api/creators/${creatorId}`, payload, config)
        .then((res) => {
            console.log(res)

            dispatch(
                AddToast({
                    message: res.data.message ?? "Creator edited successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            console.log(err)

            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );

            if (onFailureCallback && typeof onFailureCallback === "function") {
                onFailureCallback(err);
            }
        })
}

const getCountriesApiCall = (config, dispatch, onSuccessCallback = null) => {
    axios.get("/api/countries", config)
        .then((res) => {
            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error fetching countries!",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}

const submitPromotionVideoApiCall = (promotionId, payload, config, dispatch, onSuccessCallback = null, onFinallyCallback = null) => {
    axios.post(`/api/promotions/${promotionId}/submit-video-url`, payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: res?.message ?? "Link submitted!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            if (
                err?.response?.data?.message === "profile does not match with video user name"
            ) {
                dispatch(
                    AddToast({
                        message:
                            "The video was not uploaded to your TikTok profile. Please post the video on your linked TikTok profile. If you need help, please contact support.",
                        type: "error",
                        color: "#F06666",
                    })
                );
            } else if (
                err?.response?.data?.message === "Could not confirm the usage of the right song in the provided video."
            ) {
                dispatch(
                    AddToast({
                        message:
                            "The video does not contain the correct sound. Please create a video with the appropriate sound. If you need help, please contact support.",
                        type: "error",
                        color: "#F06666",
                    })
                );
            } else {
                dispatch(
                    AddToast({
                        message: err?.response?.data?.message ?? "Something went wrong, please contact support",
                        type: "error",
                        color: "#F06666",
                    })
                );
            }
        })
        .finally(() => {
            if (onFinallyCallback && typeof onFinallyCallback === "function") {
                onFinallyCallback();
            }
        });
}

const cancelPromotionApiCall = (promotionId, config, dispatch, onSuccessCallback = null) => {
    axios.delete(`/api/promotions/${promotionId}`, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Promotion has been canceled successfully. Redirecting to the homepage...",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong, please contact support",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const updateUserMeApiCall = (config, payload, dispatch, onSuccessCallback = null, onFailureCallback = null) => {
    axios.put("/api/users/me", payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: res.data.message ?? "User has been updated successfully",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong, please try again",
                    type: "error",
                    color: "#F06666",
                })
            );

            if (onFailureCallback && typeof onFailureCallback === "function") {
                onFailureCallback(err);
            }
        });
}

const getCurrentUserApiCall = (config, dispatch, onSuccessCallback = null) => {
    axios.get('/api/users/me', config)
        .then((res) => {

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong, please try again",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}

const getCreatorPromotionsApiCall = (creatorId, config, dispatch, onSuccessCallback = null) => {
    axios
        .get(`/api/creators/${creatorId}/promotions`, config)
        .then(res => {

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong, please try again",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}

const getLevelInformationApiCall = (config, dispatch, onSuccessCallback = null) => {
    axios
        .get('/api/levels', config)
        .then(res => {

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Something went wrong, please try again",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}

const createMusicianApiCall = (payload, config, dispatch, onSuccessCallback = null) => {
    axios
        .post("/api/admin/musicians", payload, config)
        .then((res) => {
            dispatch(
                AddToast({
                    message: "Musician created successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch((err) => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        });
}

const downloadCampaignReportApiCall = (userType, campaignId, reportFormat, config, dispatch, onSuccessCallback = null) => {
    axios.get(`/api/${userType}/campaigns/${campaignId}/report/${reportFormat}`, config)
        .then(res => {
            const content = res.headers["content-type"];
            const filename = res.headers["content-disposition"].split("=")[1];
            const filenameNoSpaceNoQuote = filename.replace(/\s/g, '').replace(/['"]+/g, '')

            download(res.data, filenameNoSpaceNoQuote, content);

            dispatch(
                AddToast({
                    message: "Report downloaded successfully!",
                    type: "success",
                    color: "#00CDA8",
                })
            );

            if (onSuccessCallback && typeof onSuccessCallback === "function") {
                onSuccessCallback(res);
            }
        })
        .catch(err => {
            dispatch(
                AddToast({
                    message: err?.response?.data?.message ?? "Error, contact support!",
                    type: "error",
                    color: "#F06666",
                })
            );
        })
}


export {
    getAdminCreatorPromotionsApiCall,
    getCampaignsApiCall,
    getCampaignApiCall,
    createCampaignApiCall,
    updateCampaignApiCall,
    deleteCampaignApiCall,
    getPromotionsApiCall,
    updatePromotionApiCall,
    deletePromotionApiCall,
    getAdminBillsApiCall,
    downloadBillPdfApiCall,
    updateBillApiCall,
    getUsersApiCall,
    deleteUserApiCall,
    updateUserApiCall,
    updateCreatorApiCall,
    getCountriesApiCall,
    submitPromotionVideoApiCall,
    cancelPromotionApiCall,
    updateUserMeApiCall,
    getCurrentUserApiCall,
    getCreatorPromotionsApiCall,
    getCreatorBillsApiCall,
    updateAdminCreatorApiCall,
    getLevelInformationApiCall,
    downloadCreatorBillPdfApiCall,
    createMusicianApiCall,
    downloadCampaignReportApiCall
};