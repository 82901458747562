import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";
import {Toasts} from "../components/Toasts";
import login_links from "../images/login_rechts.png";
import login_cut_vh2 from "../images/login_cut_vh2.png";
import BETAVERS from "../images/beta-version-sticker.png";
import EmailIcon from "../images/icons/Vector.png";
import PassIcon from "../images/icons/Union.png";
import {useDispatch, useSelector} from "react-redux";
import {AddToast} from "src/actions/SetToastsAction";
import {updatePassword, getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";

const Signup = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token);
    const [input, setInput] = useState({
        email: '',
        newPassword: '',
        confirmPassword: ''
    });

    const onInputChange = e => {
        const {name, value} = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const resetInput = (fieldName) => {
        setInput(prevState => ({
            ...prevState,
            [fieldName]: ''
        }));
    };

    const handleRegistration = async (e) => {
        e.preventDefault();

        if (input.newPassword !== input.confirmPassword){
            resetInput('newPassword');
            resetInput('confirmPassword');
            return dispatch(AddToast({message: "Confirmation password does not match the new password", type: "error", color: "#F06666"}));
        }
        else {
            const auth = getAuth();
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, input.email, input.newPassword);
                const user = userCredential.user;

                await signOut(auth);

                const redirectURLS = {
                    development: "http://localhost:3000/login",
                    staging: "https://test.muvid.app/login",
                    production: "https://muvid.app/login",
                }

                await sendEmailVerification(user, {url: redirectURLS[process.env.REACT_APP_MODE]});

                dispatch(AddToast({
                    message: "You have been sent a verification email. Please verify your email to proceed with the login. Redirecting to login page...",
                    type: "success",
                    color: "#00CDA8"
                }));

                setTimeout(() => {
                    history.push('/login');
                }, 5000);
            } catch (err) {
                dispatch(AddToast({
                    message: err.message ?? "Registration can't be completed for an unknown reason. Please contact support.",
                    type: "error",
                    color: "#F06666"
                }));
            }
        }
    }

    return (
        <div>
            <Toasts/>
            <STYLEDDIV
                background2={login_links}
                background={login_cut_vh2}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                    flexDirection: "column",
                }}
            >
                <img
                    src={BETAVERS}
                    style={{position: "absolute", left: "1rem", top: "1rem"}}
                />
                <div className={"SignUpBox"}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                    >
                        <Switchbutton
                            color="white"
                            background="black"
                            style={{
                                cursor: "default",
                                marginTop: "0",
                                height: "3.5rem",
                                width: "100%",
                                marginBottom: "2px",
                            }}
                        >
                            Sign Up
                        </Switchbutton>
                        <hr style={{height: "23x"}} width="100%" color="black"/>
                    </div>
                    <LoginForm onSubmit={(e) => handleRegistration(e)} display={"flex"}>
                        <div className={"EmailDiv"}>
                            <img src={EmailIcon}/>
                            <JAJAInput
                                required
                                name={"email"}
                                onChange={onInputChange}
                                value={input.email}
                                type="email"
                                placeholder="Email"
                            ></JAJAInput>
                        </div>

                        <div className={"PWDiv"}>
                            <img src={PassIcon}/>
                            <JAJAInput
                                required
                                name={"newPassword"}
                                onChange={onInputChange}
                                value={input.newPassword}
                                type="password"
                                placeholder="Password"
                            />
                        </div>
                        <div className={"PWDiv"}>
                            <img src={PassIcon}/>
                            <JAJAInput
                                required
                                name={"confirmPassword"}
                                onChange={onInputChange}
                                value={input.confirmPassword}
                                type="password"
                                placeholder="Password Confirmation"
                            />
                        </div>

                        <div>
                            <Checkbox>
                                <input type="checkbox" id={"tac"} name={"tac"} required/>
                                <label htmlFor="tac">I have read and accepted the Terms and Conditions and Privacy
                                    Policy.*</label>
                            </Checkbox>
                            <Checkbox>
                                <input type="checkbox" id={"age"} name={"age"} required/>
                                <label htmlFor="age">I am older then 13 years. Users under the age of 18 confirm that
                                    their parent or legal guardian agreeing to the use of muvid.*</label>
                            </Checkbox>
                            <Checkbox>
                                <input type="checkbox" id={"promotion"} name={"promotion"}/>
                                <label htmlFor="promotion">I accept and confirm that I want to receive promotional
                                    emails about muvid.</label>
                            </Checkbox>
                        </div>

                        <LoginBTN type="submit">Sign Up</LoginBTN>
                        <PLOGIN>
                            You want to sign in? &nbsp;
                            <span onClick={() => history.push("/login")} style={{textDecoration: "underline", cursor: "pointer"}}>
                                click here
                            </span>
                        </PLOGIN>
                    </LoginForm>
                </div>
            </STYLEDDIV>
        </div>
    );
};

export default Signup;

const STYLEDDIV = styled.div`
  background: url("${(props) => props.background}") no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1024px) {
    background: url("${(props) => props.background2}") no-repeat;
    background-size: cover;
  }

  .SignUpBox {
    background: white;
    border-radius: 6px;
    width: 30rem;
    box-shadow: rgb(0 0 0 / 40%) 3px 5px 15px 10px;
    @media only screen and (max-width: 1024px) {
      width: 20rem;
    }
    @media screen and (min-width: 1024px) {
      width: 30rem;
    }
  }
`;

const Checkbox = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  margin-bottom: 0.5rem;

  input[type="checkbox"] {
    margin-right: 0.5rem;
    vertical-align: top;
    width: 1.3rem;
    height: 1.3rem;
  }

  label {
    font-weight: 400;
    font-family: "IBM Plex Sans Regular";
    font-size: 12px;
    vertical-align: top;
    line-height: 1.25rem; /* set the line-height to match the height of the checkbox */
  }
`;

const PLOGIN = styled.p`
  font-size: 13.5px;
  font-weight: 400;
  margin-top: 13.5px;

  @media only screen and (max-width: 1024px) {
    margin-top: 1.2rem;
  }
`;

const Switchbutton = styled.button`
  padding: 0.5rem;
  margin: 0rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 2.5rem;
  margin-top: 1.5rem;
  border: none;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
`;

const LoginBTN = styled.button`
  padding: 0.5rem;
  margin: 0rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  background-color: black;
  color: white;
  border-radius: 4px;
  border: none;
  height: 2.5rem;
  margin-top: 2rem;

  @media only screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
`;

const JAJAInput = styled.input`
  width: 100%;
  border: none;

  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-family: "IBM Plex Sans Regular";
  font-size: 1rem !important;
  height: 2.5rem;

  &:-webkit-autofill {
    -webkit-background-clip: text;
    outline: none;
    border: none;
    font-size: 1rem !important;
    font-family: "IBM Plex Sans Regular";
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

const LoginForm = styled.form`
  -webkit-appearance: none;
  display: ${(props) => props.display};
  flex-direction: column;
  border-radius: 4px;
  //width: 20vw;
  padding: 3rem 1.7rem 1rem 1.7rem;
  margin-bottom: 1.2rem;

  .EmailDiv {
    display: flex;
    border: 2px solid #000000;
    border-radius: 4px;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: flex-start;

    img {
      padding-left: 0.6rem;
      padding-top: 0.1rem;
    }
  }

  .PWDiv {
    display: flex;
    border: 2px solid #000000;
    border-radius: 4px;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: flex-start;

    img {
      padding-left: 0.75rem;
    }
  }

  .ErrorDiv {
    margin: 0;
    padding: 0;
    height: 1rem;
    margin-top: 0.7rem;

    p {
      color: #af0606;
      margin: 0;
      padding: 0;
      font-size: 0.88rem;
      padding-left: 0.1rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    margin-bottom: 1.2rem;
    padding-top: 3rem;
  }

  select {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-top: 0.5rem;
    border: 2px solid #000000;
    background-color: white;
    border-radius: 4px;
    font-family: "IBM Plex Sans Regular";
    margin-bottom: 1rem;
    font-size: 1rem;
  }
`;