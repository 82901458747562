import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {createBrowserHistory} from "history";

import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {PersistGate} from "redux-persist/integration/react";
import ReactGA from "react-ga";
import "./index.css";

//REDUX Setup
import {createStore, applyMiddleware, compose} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {BrowserRouter, HashRouter} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import SomethingWentWrong from "./pages/error-pages/SomethingWentWrong";

export const history = createBrowserHistory();

const persistConfig = {
    key: "root",
    storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnchancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
    persistedReducer,
    composeEnchancer(applyMiddleware(thunk))
);
let persistor = persistStore(store);

const onRedirectCallback = (appState) => {
    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname);
};

//Init Google Analytics
const trackingId = "UA-183001412-1";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location) => {
    ReactGA.set({page: location.pathname}); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const isDevelopment = process.env.REACT_APP_MODE === "development";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter history={history}>
                <PersistGate loading={null} persistor={persistor}>
                    {isDevelopment ? (
                        <App />
                    ) : (
                        <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                            <App />
                        </ErrorBoundary>
                    )}
                </PersistGate>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
