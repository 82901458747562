import React, {lazy, Suspense, useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import {auth} from "../services/firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {loadUserInformation} from "../actions/userAction";
import "../styles/pagination_styles.css";
import {setCurrencyAction} from "../actions/setCurrencyAction";
import RouterStaticContent from "../components/RouterStaticContent";

const Campaigns = lazy(() => import('src/pages/influencer-pages/Campaigns'));
const Account = lazy(() => import('src/pages/Account'));
const Credits = lazy(() => import('src/pages/influencer-pages/Credits'));
const CampaignDetails = lazy(() => import('src/pages/influencer-pages/CampaignDetails'));
const CampaignHistory = lazy(() => import('src/pages/influencer-pages/CampaignHistory'));
const PricingInformation = lazy(() => import('src/pages/influencer-pages/PricingInformation'));
const ContactDetails = lazy(() => import('src/pages/influencer-pages/ContactDetails'));
const ChangePassword = lazy(() => import('src/pages/ChangePassword'));
const ChangeEmail = lazy(() => import('src/pages/ChangeEmail'));
const UserBills = lazy(() => import('src/pages/influencer-pages/UserBills'));
const FAQ = lazy(() => import('src/pages/influencer-pages/FAQ'));
const PageNotFound = lazy(() => import('src/pages/error-pages/PageNotFound'));
const MusicianCampaign = lazy(() => import('src/pages/musician-pages/MusicianCampaign'));
const MusicianCampaigns = lazy(() => import('src/pages/musician-pages/MusicianCampaigns'));
const NotificationSettings = lazy(() => import('src/pages/musician-pages/NotificationSettings'));
const TiktokConnectionTesting = lazy(() => import('src/pages/influencer-pages/TiktokConnectionTesting'));
const TiktokAuth = lazy(() => import('src/pages/influencer-pages/TiktokAuth'));
const TransactionHistory = lazy(() => import('src/pages/influencer-pages/TransactionHistory'));

function LazyRoute(Component) {
    return (props) => (
        <Suspense fallback={""}>
            <Component {...props} />
        </Suspense>
    );
}

const queryClient = new QueryClient();

const AppRouter = () => {
    const dispatch = useDispatch();
    const {path} = useRouteMatch();

    const [userFire, loading] = useAuthState(auth);

    const showVerifyTiktokDiv = useSelector((state) => state.Toasts.ShowVerifyTikTok, shallowEqual);

    const userObj = useSelector((state) => state.user, shallowEqual);
    const user = userObj.info;
    const accessToken = userObj.access_token;

    const isUserLoaded = Object.keys(user).length > 0 && accessToken !== null;

    const routeConfig = {
        creator: [
            {path: `${path}/account/payment-settings`, component: LazyRoute(ContactDetails), exact: true},
            {path: `${path}/campaigns`, component: LazyRoute(Campaigns), exact: true},
            {path: `${path}/credits`, component: LazyRoute(Credits), exact: true},
            {path: `${path}/credits/campaign-history`, component: LazyRoute(CampaignHistory), exact: true},
            {path: `${path}/campaigns/:campaign_id`, component: LazyRoute(CampaignDetails), exact: true},
            {path: `${path}/credits/pricing`, component: LazyRoute(PricingInformation), exact: true},
            {path: `${path}/credits/bills`, component: LazyRoute(UserBills), exact: true},
            {path: `${path}/credits/transaction-history`, component: LazyRoute(TransactionHistory), exact: true},
            {path: `${path}/faq`, component: LazyRoute(FAQ), exact: true},
            {path: `${path}/tiktok-connection-testing`, component: LazyRoute(TiktokConnectionTesting), exact: true},
            {path: `${path}/authorize/tiktok`, component: LazyRoute(TiktokAuth), exact: true},
            {path: `*`, component: LazyRoute(PageNotFound), exact: true},
        ],
        musician: [
            {path: `${path}/account/notification-settings`, component: LazyRoute(NotificationSettings), exact: true},
            {path: `${path}/musician-campaigns`, component: LazyRoute(MusicianCampaigns), exact: true},
            {path: `${path}/musician-campaigns/:id`, component: LazyRoute(MusicianCampaign), exact: true},
            {path: `*`, component: LazyRoute(PageNotFound), exact: true},
        ],
    };

    const renderRoutesForUserType = (userType) => {
        const userRoutes = routeConfig[userType] || [];

        return userRoutes.map(({path, component, exact}, index) => (
            <Route
                key={index}
                path={`${path}`}
                render={(props) => LazyRoute(component)(props)}
                exact={exact}
            />
        ));
    };

    const dynamicRoutes = renderRoutesForUserType(user.user_type);

    useEffect(() => {
        if (!userFire || loading) return;

        if (!isUserLoaded) {
            dispatch(loadUserInformation(userFire, userFire.accessToken));
        }

        dispatch(setCurrencyAction('€'));
    }, [dispatch, loading, userFire]);

    return (
        <>
            {loading ? null : userFire ? (
                <QueryClientProvider client={queryClient}>
                    <Suspense fallback={""}>
                        <RouterStaticContent showVerifyTiktokDiv={showVerifyTiktokDiv}/>

                        <Switch>
                            <Route
                                path={`${path}/account`}
                                render={(props) => LazyRoute(Account)(props)}
                                exact
                            />
                            <Route
                                path={`${path}/account/change-password`}
                                render={(props) => LazyRoute(ChangePassword)(props)}
                                exact
                            />
                            <Route
                                path={`${path}/account/change-email`}
                                render={(props) => LazyRoute(ChangeEmail)(props)}
                                exact
                            />
                            {dynamicRoutes}
                        </Switch>
                    </Suspense>
                </QueryClientProvider>
            ) : (
                <Redirect to={`/login`}/>
            )}
        </>
    );
};

export default AppRouter;