const initState = {
    playingSongName: {},
};

const PlayingMusicReducer = (state=initState, action) =>{
    switch(action.type){
        case "SET_PLAYING_MUSIC":
            return{
                playingSongName: action.payload.playingSongName
            };
        default:
            return{...state}
    }
};

export default PlayingMusicReducer;