import React, {useEffect, useMemo, useRef, useState} from "react";
import {PaymentInfoForm} from "../../styles/styles";
import axios from "axios";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Editor} from '@tinymce/tinymce-react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {createCampaignApiCall} from "../../utils/apiCalls";
import {setSongGenresAction} from "../../actions/setSongGenresAction";
import {convertDateTimeToLocalFormat, formatToDateTimeUTC} from "../../utils/utils";
import {AddToast} from "../../actions/SetToastsAction";
import {uploadFilesAndUpdatePayload} from "src/services/aws/s3"

const AddCampaign = () => {
    const initialValue = "<p>Please enter campaign description.</p>";

    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);

    const [genres, setGenres] = useState([]);
    const [musicians, setMusicians] = useState([]);

    const [isGoogleDriveSelected, setIsGoogleDriveSelected] = useState(false);
    const [description, setDescription] = useState(initialValue ?? '');

    const editorRef = useRef(null);
    const territoriesRef = useRef(0);
    const checkRef = useRef(0);

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        };
    }, [access_token]);

    const minDateTimeLocal = convertDateTimeToLocalFormat(new Date());

    const createCampaign = async (e) => {
        e.preventDefault();

        const formData = e.target;

        const startDate = new Date(formData.startDate.value);
        const endDate = new Date(formData.endDate.value);

        let formattedStartDateInUTC, formattedEndDateInUTC = null;
        if (startDate) {
            formattedStartDateInUTC = formatToDateTimeUTC(startDate.toISOString());
        }

        if (endDate) {
            formattedEndDateInUTC = formatToDateTimeUTC(endDate.toISOString());
        }

        let payload = {
            description: description,
            start_date: formattedStartDateInUTC,
            end_date: formattedEndDateInUTC,
            budget_eur: formData.budgetEur.value,
            max_level_id: formData.maxLevel.value,
            hashtags: [formData.hashtag1.value, formData.hashtag2.value, formData.hashtag3.value],
            territories: territoriesRef.current.getSelectedItems().map(({name}) => name),
            song: {
                genre: {
                    name: formData.newGenre.value || formData.genre.value,
                },
                spotify_url: formData.spotifyUrl?.value ?? null,
                title: formData.songTitle?.value ?? null,
                artist: formData.songArtist?.value ?? null,
                tiktok_url: formData.tiktokUrl.value,
                capcut_url: formData.capcutUrl.value,
            },
        };

        if (payload.end_date <= payload.start_date) {
            return dispatch(
                AddToast({
                    message: "Please select an end date that is after the start date",
                    type: "error",
                    color: "#F06666",
                })
            );
        }

        if (formData.musicians.value) {
            payload.musician_id = formData.musicians.value;
        }

        if(isGoogleDriveSelected){
            const imageFile = formData.albumCover.files[0];
            const songFile = formData.song.files[0];

            if (imageFile || songFile) {
                const uploadResult = await uploadFilesAndUpdatePayload(imageFile, songFile, payload, dispatch);

                if (uploadResult) {
                    payload = uploadResult;
                } else {
                    return;
                }
            }
        }

        createCampaignApiCall(payload, config, dispatch, (res) => {
            document.getElementById("CreateCampaignForm").reset();
        });
    }

    useEffect(() => {
        const source1 = axios.CancelToken.source();
        const source2 = axios.CancelToken.source();

        axios.get('/api/admin/songs/genres', {...config, cancelToken: source1.token})
            .then(res => {
                const genres = res.data.data.items;
                dispatch(setSongGenresAction(genres));
                setGenres(genres);
            })
            .catch(err => {
                dispatch(
                    AddToast({
                        message: err.message ?? "Error, contact support!",
                        type: "error",
                        color: "#F06666",
                    })
                );
            });

        axios.get('/api/admin/musicians', {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            params: {
                per_page: 70,
            },
            cancelToken: source1.token
        })
            .then(res => setMusicians(res.data.data.items))
            .catch(err => {
                dispatch(
                    AddToast({
                        message: err.response?.data?.message ?? "Error, contact support!",
                        type: "error",
                        color: "#F06666",
                    })
                );
            });

        // Cleanup function
        return () => {
            source1.cancel();
            source2.cancel();
        };
    }, [access_token, config, dispatch]);

    return (
        <div>
            <h1>Create New Campaign</h1>

            <div style={{display: "flex", marginTop: "10px"}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            ref={checkRef}
                            onChange={() => setIsGoogleDriveSelected(!isGoogleDriveSelected)}
                            style={{
                                color: "#00cccc",
                            }}
                        />
                    }
                    label="Pre Release Campaign"
                />
            </div>

            <PaymentInfoForm id="CreateCampaignForm" onSubmit={createCampaign}>
                <Editor
                    id="Challenge"
                    apiKey='4m46fx15tvuq3ejzyuj2fh6a2y9ffu0gb96c84owdoud52j2'
                    onInit={(evt, editor) => editorRef.current = editor}
                    // initialValue="<p>Please add your description.</p>"
                    value={description}
                    onEditorChange={(inputCampaignDescription, editor) => setDescription(inputCampaignDescription)}
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />

                <label htmlFor="startDate" className="startDate-label" style={{marginTop: "1rem"}}>Start Date</label>
                <input name="startDate" type="datetime-local" min={minDateTimeLocal}
                       title={"Scheduled start date of the campaign"}
                       placeholder="Scheduled start date of the campaign" style={{marginTop: "0rem"}} required/>
                <label htmlFor="endDate" className="endDate-label" style={{marginTop: "1rem"}}>End Date</label>
                <input name="endDate" type="datetime-local" min={minDateTimeLocal}
                       title={"Scheduled end date of the campaign"}
                       placeholder="Scheduled start date of the campaign" style={{marginTop: "0"}} required/>
                <input name="budgetEur" type="number" min="1" step="any" placeholder="Campaign budget" required/>
                <input name="spotifyUrl" type="url" pattern="https://.*" placeholder="Spotify Link of the song"
                       style={{display: !isGoogleDriveSelected ? "block" : "none"}}/>

                {
                    isGoogleDriveSelected && (
                        <>
                            <input name="songTitle" type="text" placeholder="Song Title"/>
                            <input name="songArtist" type="text" placeholder="Artist"/>
                            <label htmlFor="file-upload" className="file-upload-label" style={{marginTop: "0.5rem"}}>Album
                                Cover</label>
                            <input name="albumCover" type="file" accept="image/*" style={{marginTop: 0}} required/>
                            <label htmlFor="file-upload" className="file-upload-label"
                                   style={{marginTop: "0.5rem"}}>Song</label>
                            <input name="song" type="file" accept=".mp3,audio/*" style={{marginTop: 0}} required/>
                        </>
                    )
                }

                <input name="tiktokUrl" type="url" pattern="https://.*" placeholder="Tiktok Link of the song"/>
                <input name="capcutUrl" type="url" placeholder="Capcut Link"/>
                <input name="hashtag1" type="text" placeholder="#Hashtag1" style={{marginTop: "1rem"}}/>
                <input name="hashtag2" type="text" placeholder="#Hashtag2"/>
                <input name="hashtag3" type="text" placeholder="#Hashtag3" style={{marginBottom: "1rem"}}/>

                <label htmlFor="genre">Song Genre</label>
                <select name="genre" id="cars" placeholder="Song genre" required style={{marginBottom: "1rem"}}>
                    {genres.map((genre) => (
                        <option value={genre.name} key={genre.id}>{genre.name}</option>
                    ))}
                </select>
                <label htmlFor="genre">Musician</label>
                <select name="musicians" id="musicians" placeholder="Musician">
                    <option value="" disabled selected>Select Musician</option>
                    {musicians.map((musician) => (
                        <option value={musician.id} key={musician.id}>{`${musician.stage_name}`}</option>
                    ))}
                </select>
                <input name="newGenre" type="text" placeholder="New Genre"/>

                <Multiselect
                    style={{
                        chips: {
                            background: "#00cccc",
                        },
                        multiselectContainer: {
                            color: "#00cccc",
                        },
                        highlightOption: {
                            background: "#00cccc",
                        },
                    }}
                    ref={territoriesRef}
                    options={[
                        {name: "all", id: 8},
                        {name: "GSA", id: 4},
                        {name: "Europe", id: 5},
                        {name: "North America", id: 2},
                        {name: "Asia", id: 6},
                        {name: "LATAM", id: 1},
                        {name: "Africa", id: 3},
                        {name: "Oceania", id: 7},
                    ]}
                    displayValue="name"
                    placeholder={"Available territories"}
                    required
                />


                <h4 style={{marginTop: "1rem"}}>Max Creator Level</h4>
                <FormControl fullWidth>
                    <Select name="maxLevel" required>
                        <MenuItem value={1}>Level 0</MenuItem>
                        <MenuItem value={2}>Level 1</MenuItem>
                        <MenuItem value={3}>Level 2</MenuItem>
                        <MenuItem value={4}>Level 3</MenuItem>
                        <MenuItem value={5}>Level 4</MenuItem>
                        <MenuItem value={6}>Level 5</MenuItem>
                        <MenuItem value={7}>Level 6</MenuItem>
                    </Select>
                </FormControl>
                <input
                    style={{marginBottom: "2rem", marginTop: "2rem"}}
                    type="submit"
                    value="Save"
                />
            </PaymentInfoForm>
        </div>
    );
};

export default AddCampaign;