import search_icon from "../../images/search_icon.png";
import React, {useRef, useState} from "react";
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";

const SearchBar = ({headerText, countries, isExtraSearch = false}) => {
    const history = useHistory();
    const searchTextRef = useRef();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const defaultSearchParams = Object.fromEntries(searchParams.entries());

    const [country, setCountry] = useState(defaultSearchParams.countryName);
    const [searchObj, setSearchObj] = useState(defaultSearchParams);

    const changeCountry = (key) => (e) => {
        setCountry(e.target.value);
        handleChange(key)(e)
    }

    const handleChange = (key) => (e) => {
        const value = e.target.value;
        setSearchObj(prevState => {
            const newState = {...prevState};
            if (value === 'del') {
                delete newState[key];
            } else {
                newState[key] = value;
            }
            return newState;
        });
    };

    const handleSearch = (searchParam) => {
        console.log(searchParam)

        let historyParams = new URLSearchParams();
        for (const key in searchParam) {
            if (searchParam[key]){
                historyParams.append(key, searchParam[key]);
            }
        }

        if (historyParams.get('page')) {
            historyParams.set('page', '1');
        }

        historyParams.append('page', '1')

        history.push(`?${historyParams.toString()}`);
    };

    return (
        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <h1>{headerText}</h1>

            <div style={{display: "flex", float: "right", height: "2.2rem"}}>
                {isExtraSearch && (
                    <>
                        <select name="status" id="status" title="Status" onChange={handleChange('status')}
                                defaultValue={defaultSearchParams.status}>
                            <option value="del">Status</option>
                            <option value="blocked">blocked</option>
                            <option value="incomplete">incomplete</option>
                            <option value="active">active</option>
                        </select>
                        <select name="levels" id="levels" title="Level" onChange={handleChange('levelId')}
                                defaultValue={defaultSearchParams.levelId}>
                            <option value="del">Level</option>
                            <option value="1">0</option>
                            <option value="2">1</option>
                            <option value="3">2</option>
                            <option value="4">3</option>
                            <option value="5">4</option>
                            <option value="6">5</option>
                            <option value="7">6</option>
                        </select>
                        <select name="country" title="Country" onChange={changeCountry('countryName')}
                                value={country}>
                            <option value="del">Country</option>
                            {countries && countries.map((country, index) => (
                                <option key={index} value={country.name}>{country.name}</option>
                            ))}
                        </select>

                    </>
                )}

                <InputContainer>
                    <input
                        ref={searchTextRef}
                        placeholder={'tk username, name, email'}
                        defaultValue={defaultSearchParams.user}
                        onKeyDown={(e) => (e.key === 'Enter' && handleSearch({...searchObj, user: searchTextRef.current.value}))}
                    />
                    <button onClick={() => handleSearch({...searchObj, user: searchTextRef.current.value})}>
                        <img src={search_icon} alt={"search"}/>
                    </button>
                </InputContainer>
            </div>
        </div>
    );
};

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        height: 100%;
        border-radius: 0;
        border: 1px solid gray;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        font-family: IBM Plex Sans, serif;
        font-size: 1rem;
        outline: none;
    }

    button {
        height: 100%;
        background-color: #00cccc;
        border: none;
        border-radius: 0;
        width: 2.5rem;
        cursor: pointer;
    }

    img {
        height: 1.1rem;
        opacity: 80%;
        margin-top: 3px
    }
    `;

export default SearchBar;