const initState = {
    info: {},
};

const SongGenresReducer = (state=initState, action) =>{
    switch(action.type){
        case "SET_ALL_GENRES":
            return{
                genres: action.payload.genres
            };
        default:
            return{...state}
    }
};

export default SongGenresReducer;