const initState = {
    info: {},
    active_page: "campaigns",
    access_token: null,
};

const UserReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_CURRENT_USER_INFORMATION":
            return {
                ...state,
                info: action.payload.info.data,
                access_token: action.payload.access_token ?? state.access_token
            };
        case "SET_ACTIVE_PAGE":
            return {
                ...state,
                active_page: action.payload
            };
        case "SET_ACCESS_TOKEN":
            return {
                ...state,
                access_token: action.payload
            };
        default:
            return {...state};
    }
};

export default UserReducer;
