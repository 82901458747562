import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AdminNav from 'src/components/admin-components/dashboard/AdminNav';
import AdminDrawer from 'src/components/admin-components/dashboard/AdminDrawer';

const Dashboard = ({routes}) => {
  const classes = useStyles();

  let { path, url } = useRouteMatch();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);

  return (
      <div className={classes.root}>
        <CssBaseline />
        <AdminNav isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/>
        <AdminDrawer url={url} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/>

        <main className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={`${path}${route.path}`}
                        exact={true}
                        component={route.component}
                    />
                ))}
              </>
            </Switch>
          </Container>
        </main>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default Dashboard;