import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import CampaignReducer from "./CampaignReducer";
import {ToastReducer} from "./ToastReducer";
import CurrencyReducer from "./CurrencyReducer";
import PlayingMusicReducer from "./PlayingMusicReduceer";
import SongGenresReducer from "./SongGenresReducer";
import LevelInformationReducer from "./LevelInformationReducer";
import MusicianCampaignsReducer from "./MusicianCampaignsReducer";

const rootReducer = combineReducers({
    user: UserReducer,
    CurrentSong: CampaignReducer,
    Toasts: ToastReducer,
    currency: CurrencyReducer,
    playingSongName: PlayingMusicReducer,
    genres: SongGenresReducer,
    levelInfo: LevelInformationReducer,
    musicianCampaigns: MusicianCampaignsReducer
});

export default rootReducer;
