import React, {useEffect, useState} from "react";
import EmailIcon from "../images/icons/Vector.png";
import PassIcon from "../images/icons/Union.png";
import {auth, signInWithEmailAndPassword} from "../services/firebase/auth";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import login_links from "../images/login_rechts.png";
import login_cut_vh2 from "../images/login_cut_vh2.png";
import {Toasts} from "../components/Toasts";
import {useDispatch} from "react-redux";
import {AddToast} from "src/actions/SetToastsAction";
import axios from "axios";
import BETAVERS from "../images/beta-version-sticker.png";
import {getAnalytics, logEvent} from "firebase/analytics";
import {getAuth, signOut} from "firebase/auth";
import {resetUserInformation} from "../actions/userAction";


const ActualLoginPage = () => {
        const history = useHistory();
        const dispatch = useDispatch();

        const [password, setPassword] = useState("");
        const [email, setEmail] = useState("");
        const [errorMSG, setErrorMSG] = useState(null);

        const publishErrorMessages = (msg, err) => {
            setErrorMSG(msg);

            return dispatch(AddToast({
                message: err?.message ?? msg,
                type: "error",
                color: "#F06666"
            }));
        }

        const login = async (e) => {
            e.preventDefault();

            try {
                const userLog = await signInWithEmailAndPassword(email, password);

                if (userLog.user['_delegate'].emailVerified === false) {
                    const auth = getAuth();
                    await signOut(auth)
                    return publishErrorMessages("Please verify your email address before you can login")
                }

                setErrorMSG(null);

                let token = userLog.user.multiFactor.user.accessToken || userLog.accessToken;

                axios.post('/api/users', {email: email}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then(res => {
                        const user = res.data.data;
                        let activePage;

                        if (user?.user_type === 'musician') {
                            activePage = "account";
                        }
                        else {
                            activePage = user?.creator?.tiktok_username ? "campaigns" : "account";
                        }

                        dispatch({
                            type: "SET_ACTIVE_PAGE",
                            payload: activePage,
                        });

                        dispatch({
                            type: "SET_ACCESS_TOKEN",
                            payload: token,
                        });

                        dispatch(resetUserInformation(res.data));
                        history.push(`/app/${activePage}`);
                    })
                    .catch(err => {
                        return publishErrorMessages("Something went wrong, please contact support", err)
                    });
            }
            catch (err) {
                switch (err.code) {
                    case "auth/invalid-email":
                        return publishErrorMessages("Invalid email address", err)
                    case "auth/user-disabled":
                        return publishErrorMessages("This account has been disabled", err)
                    case "auth/user-not-found":
                        return publishErrorMessages("User with that email was not found", err)
                    case "auth/wrong-password":
                        return publishErrorMessages("Incorrect password", err)
                    case "auth/too-many-requests":
                        return publishErrorMessages("Too many requests, please try again later", err)
                    default:
                        return publishErrorMessages(err.message, err)
                }
            }
        }

        const forgotPassword = () => {
            auth
                .sendPasswordResetEmail(email)
                .then(function () {
                    dispatch(
                        AddToast({
                            message: "You have been sent an email to reset your password",
                            type: "success",
                            color: "#00CDA8",
                        })
                    );
                })
                .catch(function (e) {
                    dispatch(
                        AddToast({
                            message: "User with the specified email was not found.",
                            type: "error",
                            color: "#F06666",
                        })
                    );
                });
        };

        useEffect(() => {
            const analytics = getAnalytics();
            logEvent(analytics, "login_page", {device: "hallo123"});
        }, []);


        return (
            <div>
                <Toasts/>
                <STYLEDDIV
                    background2={login_links}
                    background={login_cut_vh2}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh",
                        flexDirection: "column",
                    }}
                >
                    <img
                        src={BETAVERS}
                        style={{position: "absolute", left: "1rem", top: "1rem"}}
                    />
                    <div
                        style={{
                            background: "white",
                            borderRadius: "6px",
                            width: "20rem",
                            boxShadow: "rgb(0 0 0 / 40%) 3px 5px 15px 10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                        >
                            <Switchbutton
                                color="white"
                                background="black"
                                style={{
                                    cursor: "default",
                                    marginTop: "0",
                                    height: "3.5rem",
                                    width: "100%",
                                    marginBottom: "2px",
                                }}
                            >
                                Login
                            </Switchbutton>
                            <hr style={{height: "23x"}} width="100%" color="black"/>
                        </div>
                        <LoginForm onSubmit={login} display={"flex"}>
                            <div className={"EmailDiv"}>
                                <img src={EmailIcon}/>
                                <JAJAInput
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    type="text"
                                    placeholder="Email"
                                />
                            </div>

                            <div className={"PWDiv"}>
                                <img src={PassIcon}/>
                                <JAJAInput
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    type="password"
                                    placeholder="Password"
                                />
                            </div>
                            <div className={"ForgotDiv"}>
                                <FORGOT>
                                    Forgot your password? &nbsp;
                                    <span onClick={forgotPassword} style={{textDecoration: "underline", cursor: "pointer"}}>
                                        click here
                                    </span>
                                </FORGOT>
                            </div>
                            {
                                errorMSG && (
                                    <div className="ErrorDiv">
                                        <p>{errorMSG}</p>
                                    </div>
                                )
                            }

                            <LoginBTN type="submit">Log In</LoginBTN>
                            <PLOGIN>
                                You want to sign up for muvid? &nbsp;
                                <span onClick={() => history.push("/signup")}
                                      style={{textDecoration: "underline", cursor: "pointer"}}>
                                    click here
                                </span>
                            </PLOGIN>
                        </LoginForm>
                    </div>
                </STYLEDDIV>
            </div>
        );
    }
;

export default ActualLoginPage;

const STYLEDDIV = styled.div`
  background: url("${(props) => props.background}") no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1024px) {
    background: url("${(props) => props.background2}") no-repeat;
    background-size: cover;
  }
`;

const FORGOT = styled.p`
  font-size: 13.5px;
  font-weight: 400;
  margin-left: 2px;
  margin-top: 4px;
`;

const PLOGIN = styled.p`
  font-size: 13.5px;
  font-weight: 400;
  margin-top: 13.5px;

  @media only screen and (max-width: 1024px) {
    margin-top: 1.2rem;
  }
`;

const Switchbutton = styled.button`
  padding: 0.5rem;
  margin: 0rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 2.5rem;
  margin-top: 1.5rem;
  border: none;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
`;

const LoginBTN = styled.button`
  padding: 0.5rem;
  margin: 0rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  background-color: black;
  color: white;
  border-radius: 4px;
  border: none;
  height: 2.5rem;
  margin-top: 12%;

  @media only screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
`;

const JAJAInput = styled.input`
  border: none;

  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-family: "IBM Plex Sans Regular";
  font-size: 1rem !important;
  height: 2.5rem;

  &:-webkit-autofill {
    -webkit-background-clip: text;
    outline: none;
    border: none;
    font-size: 1rem !important;
    font-family: "IBM Plex Sans Regular";
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

const LoginForm = styled.form`
  -webkit-appearance: none;
  display: ${(props) => props.display};
  flex-direction: column;
  border-radius: 4px;
  //width: 20vw;
  padding: 3rem 1.7rem 1rem 1.7rem;
  margin-bottom: 1.2rem;

  .EmailDiv {
    display: flex;
    border: 2px solid #000000;
    border-radius: 4px;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: flex-start;

    img {
      padding-left: 0.6rem;
      padding-top: 0.1rem;
    }
  }

  .PWDiv {
    display: flex;
    border: 2px solid #000000;
    border-radius: 4px;
    align-items: center;
    justify-content: flex-start;

    img {
      padding-left: 0.75rem;
    }
  }

  .ErrorDiv {
    margin: 0;
    padding: 0;
    min-height: 1rem;
    margin-top: 0.7rem;

    p {
      color: #af0606;
      margin: 0;
      padding: 0;
      font-size: 0.88rem;
      padding-left: 0.1rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    margin-bottom: 1.2rem;
    padding-top: 3rem;
  }

  select {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-top: 0.5rem;
    border: 2px solid #000000;
    background-color: white;
    border-radius: 4px;
    font-family: "IBM Plex Sans Regular";
    margin-bottom: 1rem;
    font-size: 1rem;
  }
`;
