import axios from "axios";

export const loadUserInformation = (user, token) => async (dispatch) => {
    if (user) {
        axios.get(`/api/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                dispatch({
                    type: "SET_CURRENT_USER_INFORMATION",
                    payload: {
                        info: res.data,
                        access_token: token,
                    },
                });
            })
            .catch(e => {
                console.log(e)
            })
    }
};

export const resetUserInformation = (info_dict) => (dispatch) => {
    dispatch({
        type: "SET_CURRENT_USER_INFORMATION",
        payload: {
            info: info_dict,
        },
    });
};
