import React, {useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {AddToast} from "src/actions/SetToastsAction";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {deletePromotionApiCall} from "../../utils/apiCalls";
import updatePromotionVideoLink from "../../utils/updatePromotionVideoLink";

const PendingPromo = ({promo}) => {
    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token);
    const [deleteText, setDeleteText] = useState("Delete");
    const [editVideoUploadDate, setEditVideoUploadDate] = useState(false);

    const config = {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };

    return (
        <>
            <PendingPromoDiv>
                <Chip
                    style={{
                        backgroundColor: "rgba(0, 203, 205, 0.01)",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "start"
                    }}
                    className="chip"
                    icon={<AccountCircleOutlinedIcon/>}
                    label={promo.creator.tiktok_username}
                />
                <Chip
                    style={{
                        backgroundColor: "rgba(0, 203, 205, 0.01)",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "start"
                    }}
                    className="chip"
                    icon={<AssignmentTurnedInOutlinedIcon/>}
                    label={promo.created_at}
                />

                <Chip
                    style={{
                        backgroundColor: "rgba(0, 203, 205, 0.01)",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "start",
                        display: editVideoUploadDate ? "none" : ""
                    }}
                    className="chip"
                    icon={<AccessTimeIcon/>}
                    label={promo.video_uploaded_at}
                    onClick={() => setEditVideoUploadDate(!editVideoUploadDate)}
                />

                {
                    editVideoUploadDate && (
                        <form onSubmit={(e) => updatePromotionVideoLink(e, config, promo.id, dispatch)}>
                            <input type="datetime-local" id="myDateTimeInput" step="1" lang="en-GB"/>
                            <DeleteButton
                                style={{backgroundColor: "black"}}
                                variant="contained"
                                color="secondary"
                                type="submit"
                            >
                                Submit
                            </DeleteButton>
                        </form>
                    )
                }

                <div className="buttons"
                     style={{marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <form style={{display: "block"}}
                          onSubmit={(e) => updatePromotionVideoLink(e, config, promo.id, dispatch, "pending")}>
                        <label>
                            <input type="text"
                                   name="videolink"
                                   placeholder="VideoLink"/>
                        </label>
                        <DeleteButton
                            style={{backgroundColor: "black"}}
                            variant="contained"
                            color="secondary"
                            type="submit"
                        >
                            Submit
                        </DeleteButton>
                    </form>

                    <DeleteButton
                        style={{backgroundColor: "#D32424"}}
                        onClick={deleteText === 'confirm' ? () => deletePromotionApiCall(promo.id, config, dispatch) : () => setDeleteText('confirm')}
                        variant="contained"
                        color="secondary"
                    >
                        {deleteText}
                    </DeleteButton>
                </div>

            </PendingPromoDiv>
        </>
    );
};

export default PendingPromo;

const PendingPromoDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 97%;
  height: 43px;
  margin: auto;
  background-color: #c4c4c4;

  button {
    width: auto !important;
  }

  .chip {
    margin: 1rem;
  }
`;

const DeleteButton = styled(Button)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  //margin-left: auto !important;
  margin-right: 0.5rem !important;
  white-space: nowrap;
  height: 28px !important;
  width: 130px !important;
  font-family: "IBM Plex Sans Regular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: x-small !important;
  line-height: 26px !important;
`;
