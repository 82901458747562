import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {getAnalytics} from "firebase/analytics";
import {getAuth, signOut} from "firebase/auth";
import FirebaseAccountKey from "src/FirebaseAccountKey.json";

const firebaseConfig = FirebaseAccountKey;
const app = firebase.initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(app);
const auth = app.auth();
const db = app.firestore();

const signInWithEmailAndPassword = async (email, password) => {
    return await auth.signInWithEmailAndPassword(email, password);
};

const logout = async (e) => {
  e.preventDefault();

  const auth = getAuth();
  await signOut(auth);

  localStorage.clear();

  //using window.location.href instead of history.push in order to reset state of the app
  window.location.href = process.env.REACT_APP_BASE_URL + "/login";
}

export { auth, db, signInWithEmailAndPassword, logout };
