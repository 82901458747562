import React from 'react';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";
import {Link as RouterLink} from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import ListItemText from "@material-ui/core/ListItemText";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import GroupIcon from "@material-ui/icons/Group";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StorageIcon from "@material-ui/icons/Storage";
import HeadphonesIcon from '@material-ui/icons/LibraryMusic';
import {logout} from "../../../services/firebase/auth";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";

const DRAWER_WIDTH = 265;

const menuItems = [
    { path: "/create-campaign", icon: MusicNoteIcon, text: "Create Campaign" },
    { path: "/create-musician", icon: PersonAddIcon, text: "Create Musician" },
    { path: "/campaigns/active", icon: PlaylistAddCheckIcon, text: "Active Campaigns" },
    { path: "/campaigns/archived", icon: PlaylistAddCheckIcon, text: "Archived Campaigns" },
    { path: "/creators", icon: GroupIcon, text: "Creators" },
    { path: "/musicians", icon: HeadphonesIcon, text: "Musicians" },
    { path: "/invoices", icon: ReceiptIcon, text: "Invoices" },
    { path: "/background-processes", icon: StorageIcon, text: "Background Processes" },
    { path: "/logout", icon: ExitToAppIcon, text: "Logout", action: logout }
];

export default function AdminDrawer({url, isDrawerOpen, setIsDrawerOpen}) {
    const classes = useStyles();

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
            }}
            open={isDrawerOpen}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={() => setIsDrawerOpen(false)}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider className={clsx(classes.borderStyle)}/>
            <List>
                {menuItems.map(({ path, icon: Icon, text, action }, index) => (
                    <React.Fragment key={index}>
                        <StyledLink to={`${url}${path}`} key={`${index}_${path}`}>
                            <ListItem button onClick={action ? (e) => action(e) : undefined}>
                                <ListItemIcon><Icon /></ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        </StyledLink>
                        <Divider className={clsx(classes.borderStyle)}/>
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
}

const StyledLink = styled(RouterLink)`
    text-decoration: none;
    color: black;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        backgroundColor: "#C4C4C4",
        position: "relative",
        whiteSpace: "nowrap",
        width: DRAWER_WIDTH,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    borderStyle: {
        borderTop: "1px solid black"
    },
    borderStyleX: {
        borderTop: "1px solid black",
        borderBottom: "1px solid black"
    }
}));
