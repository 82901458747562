import styled from 'styled-components';
import React from "react";
import Chip from "@material-ui/core/Chip";
import AccountBoxIcon from "@material-ui/icons/AccountCircleOutlined";
import EmailIcon from '@material-ui/icons/Email';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import {BlackButton} from "../../styles/styles";
import {useHistory} from "react-router-dom";

const Musician = ({musician}) => {
    const history = useHistory();

    const {stage_name: stageName, first_name: firstName, last_name: lastName, email} = musician;
    const fullName = `${firstName} ${lastName}`;

    const redirectToCampaigns = () => {
        history.push(`/admin/campaigns?musician=${musician.id}`);
    }

    return (
        <StyledDiv>
            <HeaderChip
                icon={<AccountBoxIcon/>}
                title="Full Name"
                label={fullName}
            />

            <HeaderChip
                icon={<EmailIcon/>}
                title="Email Address"
                label={email}
            />

            <HeaderChip
                icon={<MusicVideoIcon/>}
                title="Stage Name"
                label={stageName}
            />

            <BlackButton onClick={redirectToCampaigns}>Campaigns</BlackButton>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    margin: 1rem 2rem;
    padding: 2rem;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`;

const HeaderChip = styled(Chip)`
    background-color: #b1b1b1 !important;
    border-radius: unset !important;
    font-style: normal !important;
    line-height: 26px !important;
    padding: 1rem !important;
    flex: 1;
`;

export default Musician;