import React from 'react';
import GlobalStyle from "./styles/GlobalStyle"
import ScrollToTop from "./utils/ScrollToTop";
import BaseRouter from "./router/BaseRouter";


function App() {
    return (
        <div className="App">
            <GlobalStyle/>
            <ScrollToTop/>
            <BaseRouter/>
        </div>
    );
}

export default App;
