import React, {useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {resetUserInformation} from "../../../actions/userAction";
import {getCountriesApiCall} from "../../../utils/apiCalls";
import axios from "axios";
import {AddToast} from "../../../actions/SetToastsAction";
import {useHistory} from "react-router-dom";

const TiktokConnectionModal = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const userObj = useSelector((state) => state.user, shallowEqual);
    const {info: user, access_token: accessToken} = userObj;
    const isUserLoaded = Object.keys(user).length > 0 && accessToken !== null;

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [code, setCode] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [displayValue, setDisplayValue] = useState(null);

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
    }, [accessToken]);

    const isDisabled = isLoading || !!error;

    const closeTiktokDiv = () => {
        dispatch({
            type: "HIDE_VERIFY",
        });

        history.push("/app/account", {shallow: true});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setIsLoading(true);

        console.log(code)

        const payload = {
            creator_id: user.creator.id,
            country_id: selectedCountry,
            code: code,
        }

        console.log("Backend Request Payload", payload)

        axios
            .post("/api/creator/authorize/tiktok", payload, config)
            .then((res) => {
                console.log("response", res)

                dispatch(resetUserInformation(res.data));

                setDisplayValue("none")
                closeTiktokDiv();

                dispatch(
                    AddToast({
                        message: "Tiktok profile connected successfully",
                        type: "success",
                        color: "#00CDA8",
                    })
                )
            })
            .catch((err) => {
                const message = err?.response?.data?.message;
                console.log("error", message)

                dispatch(
                    AddToast({
                        message: message ?? "Error, contact support!",
                        type: "error",
                        color: "#F06666",
                    })
                );

                setError(message ?? "Something went wrong. Please try again later.")
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);

        const code = urlSearchParams.get('code');
        setCode(code);
    }, [])

    useEffect(() => {
        isUserLoaded && getCountriesApiCall(config, dispatch, (res) => {
            setCountries(res.data.data)
        })
    }, [config, dispatch, isUserLoaded])

    return (
        <TiktokDiv style={{display: displayValue}}>
            <div onClick={closeTiktokDiv} className="CloseIconDiv">
                <FontAwesomeIcon icon={faTimes}/>
            </div>

            <h2 className={'header'}>Connect your Tiktok Profile</h2>

            <StyledForm onSubmit={handleSubmit}>
                <select name="country" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}
                        required>
                    <option value="" disabled>Select your country</option>
                    {countries && countries.map((country, index) => (
                        <option key={index} value={country.id}>{country.name}</option>
                    ))}
                </select>
                <button className={'submit-button'} type='submit' disabled={isDisabled}>
                    Connect
                </button>
            </StyledForm>

            {isLoading && (
                <div className={"SpinnerDiv"}>
                    <h4>Please be patient while we are establishing connection with Tiktok</h4>
                    <CircularProgress/>
                </div>
            )}

            {error && (
                <div className="ErrorDiv">
                    <p>{error}</p>
                </div>
            )}
        </TiktokDiv>
    );
};

const TiktokDiv = styled.div`
    -webkit-appearance: none;

    @media only screen and (max-width: 768px) {
        width: 90vw;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    min-width: 300px;
    max-width: 1000px;
    background-color: white;
    box-shadow: 0 0 10px 7px rgba(0, 203, 205, 0.2);
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    position: fixed;
    top: 45%;
    left: 50%;
    z-index: 100000;
    transform: translateX(-50%) translateY(-50%);

    .header {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .CloseIconDiv {
        margin-left: auto !important;
        cursor: pointer;
    }

    .ErrorDiv {
        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        width: 60%;
        background-color: #f8d7da; /* Light red background for error messages */
        color: #721c24; /* Dark red text color for readability */
        font-family: Arial, sans-serif; /* Simple and readable font */
        padding: 20px; /* Padding around the text */
        border-radius: 5px; /* Rounded corners */
        margin: 20px; /* Margin around the div to separate it from other content */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow to make it pop a bit */

        p {
            font-size: 16px; /* Slightly larger text size */
            line-height: 1.5; /* Space out the lines a bit for readability */
        }
    }

    .SpinnerDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    
    .submit-button {
        margin-top: 0.5rem;
        
        font-weight: bold;
        font-size: 1rem;
        font-family: "IBM Plex Sans Regular",serif;

        background-color: black;
        color: white;
        white-space: nowrap;
        cursor: pointer;

        border: none;
        border-radius: 4px;
        padding: 0.6rem 4rem;
    }

    .submit-button:disabled {
        cursor: not-allowed;
        background-color: #808080;
    }
`;


const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 60%;

    input {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-top: 0.5rem;
        border: 2px solid #000000;
        border-radius: 4px;
        font-family: "IBM Plex Sans Regular", serif;
        font-size: 1rem;
    }

    select {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-top: 0.5rem;
        border: 2px solid #000000;
        background-color: white;
        border-radius: 4px;
        font-family: "IBM Plex Sans Regular", serif;
        font-size: 1rem;
    }

    .SubmitButton {
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        input {
            font-size: 0.8rem;
        }
    }
`;

export default TiktokConnectionModal;